import React from "react";
import "./loading-page.css";
import anglesimg from "../images/loading.gif";
import Header from "../header/header";
// import { PAGETYPE } from "../../functions/helpers/constants";
// import { commonFunction } from "../../functions/helpers/analytics-function";
// import Header from "../../helpers/header/header";
let i = 0;
class LoadingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  componentDidMount() {
    i = 0;
    setTimeout(() => {
      this.moveProgressBar()
    }, 1000);
  }

  moveProgressBar = () => {
    if (i === 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      if (elem) {
        var width = 1;
        var id = setInterval(frame, 15);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
            i = 0;
          } else {
            width++;
            elem.style.width = width + "%";
          }
        }
      }
    }
  }

  render() {
    return (
      <div>
        <div id="loader-wrapper">
          <Header />
          <div className="loader-position">
            <img src={anglesimg} alt="" className="loaderGifWidth" />
            <div id="myProgress">
              <div id="myBar"></div>
            </div>
            <p style={{ marginTop: '10px', fontSize: 14, textAlign: 'center', color: 'white' }}>Loading Experience... Please wait for a while...</p>
          </div>

          {/* <span></span> */}

          {/* <div className="loaderbuttonposition" style={{ display: "none" }}> */}
          {/* <div className="" > */}
          {/* <button className="loaderbutton" style={{ border: 0, outline: 0 }}>
            Click{" "}
          </button> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="positioninternettext marginbottominternettext">
          For Slow Internet Speed &nbsp;
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => this.loadLiteLobby()}
          >
            <b>
              <u>Click Here</u>
            </b>
          </div>
          
        </div> */}
          {/* <div className="positioninternettext">
         to open lite version
        </div> */}
        </div>
      </div>
    );
  }
}
export default LoadingPage;
