import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import tutorialimg1 from '../images/desktopangels1.jpg';
import tutorialimg2 from '../images/mobangels2.jpg';

export default class InfoPage extends Component {

    isMobileDevice = () => {
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <div>
                <div className="modal" id="info1" >
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 class="modal-title green-text" id="staticBackdropLabel">Info</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={() => this.infoButtonclose()}>&times;</button>
                            </div>
                            <div className="modal-body">

                                {
                                    (this.isMobileDevice()) ?
                                        <img src={tutorialimg2} style={{ width: '100%' }} />
                                        :
                                        <img src={tutorialimg1} style={{ width: '100%' }} />
                                }

                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
