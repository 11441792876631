
import firebase from 'firebase';

let dbFirebaseApp;
SetupFirebase();

/**
* Firebase Initialization Function
* This must be called before any firebase query
*/
function SetupFirebase() {
    var firebaseConfig = {
        apiKey: "AIzaSyC9MVIIOcSWDFT30U1s75s5sYdz3dhudpo",
        authDomain: "rtc-server.firebaseapp.com",
        databaseURL: "https://rtc-server.firebaseio.com",
        projectId: "rtc-server",
        storageBucket: "rtc-server.appspot.com",
        messagingSenderId: "38884098845",
        appId: "1:38884098845:web:8f3f0c021375b4388257f4"
    };
    // Initialize Firebase
    dbFirebaseApp = firebase.initializeApp(firebaseConfig, 'db');
}

export default dbFirebaseApp;
