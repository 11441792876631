const PAGETYPE = {
  LOADING: 0,
  SELECTION: 1,
  ENTRANCE: 2,
  LOBBY: 3,
  WEBINAR: 4,
  ENTERTAINMENT: 5,
  MEETING: 6,
  CHATROOM: 7,
  LOGIN: 8,
  SPEAKERWEBINAR: 9,
  MODERATORWEBINAR: 10,
  MODERATORBREAKOUT: 11,
  MODERATOREXTRA: 12,
  ARCHIVEDGALLARY: 13,
  DISPLAYGALLARY: 14,
  BREAKOUTNURSE: 15,
  WEBINARENTRANCE: 16,
  LOBBY_LITE: 17,
  LOBBY_LITE_BACK: 18,
  SPEAKERBREAKOUT: 19,
  WAITINGAREA: 20,
  BREAKOUTNEUROLOGIST:21,
  ARMODELPAGE:22
}

export {
  PAGETYPE
};
