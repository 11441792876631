import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle } from "@material-ui/core";
import logoleft from '../images/med.png';
import logoright from '../images/logo.png';
import cross from '../images/medexIcon/cross.png';
import './videodialog.css'
function VideoInDialog(props) {

  return (
    <div>

      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        fullWidth
        maxWidth="sm" style={{zIndex: 1230000}}>
        <div>
        </div>
        <DialogTitle id="customized-dialog-title" style={{ paddingBottom: '5px' }} >

          <div className="flex__logo">

            <img style={{ width: '90px' }} src={logoleft} />
            <img src={cross} className="closevideobtn" onClick={props.onClose} />

            {/* <img style={{ width: '135px' }} src={logoright} /> */}
          </div>
        </DialogTitle>
        <DialogContent>

          <div>
            <iframe

              src={props.url}
              frameBorder="0"
              className="videowidth"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VideoInDialog;
