import React, { Component } from "react";
import $ from "jquery";

import {
  loadModeratorMessages,
  saveMessageToDatabaseforModerator,
} from "../functions/features/chat/chat-functions";
import "./speaker-page.css";
import "./chatpanelwebinar.css";
import SendImg from "../images/send.png";
import logout from "../images/medexIcon/logout.png";
import breakout from "../images/breakout-icon.png"

import { PAGETYPE } from "../functions/helpers/constants";
// import { StartSpeakerWebinarMeeting } from "../functions/helpers/webinar-zoom-2d";
import authFirebaseApp from "../functions/features/auth-firebase-app";
import { analyticsFunction } from "../functions/helpers/analytics-function";
import Header from "../header/header";

const roomName = 'speakerWebinar'

export default class SpeakerPageWebinar extends Component {
  constructor(props) {
    super(props);

    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('userRole') === 'speaker' && localStorage.getItem('uid') !== '') {
      console.warn('welcome')
    } else {
      window.location.href = '/'
    }
    // authFirebaseApp.auth().onAuthStateChanged((user) => {
    //   if ((user) && (localStorage.getItem('userRole') === 'speaker')) {
    //     console.warn('user speaker-webinar', user);
    //   }
    //   else {
    //     console.warn('user not found')
    //     window.location.href = '/';
    //   }
    // });
    // StartSpeakerWebinarMeeting(2);
    loadModeratorMessages("moderatorRoomMEDEX");
    $(function () {
      var arrow = $(".chat-head img");

      arrow.on("click", function () {
        var src = arrow.attr("src");

        $(".chat-body").slideToggle("fast");
        if (
          src ===
          "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
        ) {
          arrow.attr(
            "src",
            "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
          );
        } else {
          arrow.attr(
            "src",
            "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
          );
        }
      });
    });

    analyticsFunction(roomName)
  }

  goToBreakout = () => {
    console.warn('go to breakout clicked')

    localStorage.setItem('SpeakerRoom', PAGETYPE.SPEAKERBREAKOUT);
    window.location.reload();
  }

  logOut() {
    authFirebaseApp
      .auth()
      .signOut()
      .then((res) => {
        console.log("log out");
        localStorage.clear();
        window.location.href = "/";
      });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  sendMessage = (e) => {
    e.preventDefault();
    let msg = document.getElementById("message-txt").value;
    console.log('typed msg', msg)
    if (msg !== "") {
      saveMessageToDatabaseforModerator(msg, "moderatorRoomMEDEX");
    } else {
      console.warn("Button should be disabled now");
    }
  }

  onUnload = (e) => {
    e.preventDefault();
    e.returnValue = "Speaker page unload";
  };

  openchat = () => {
    document.getElementById("moderator1").classList.add("bottomtotop");
    document.getElementById("message-form").classList.add("bottomtotop");
    document.getElementById("CloseChat").classList.remove("hidebtn");
    document.getElementById("OpenChat").classList.add("hidebtn");
  };

  closechat = () => {
    document.getElementById("moderator1").classList.remove("bottomtotop");
    document.getElementById("message-form").classList.remove("bottomtotop");
    document.getElementById("OpenChat").classList.remove("hidebtn");
    document.getElementById("CloseChat").classList.add("hidebtn");
  };

  render() {
    return (
      <div>
        <Header headerType="speaker" />
        <div className="breakouticonposition" onClick={() => this.logOut()}>
          <img
            src={logout}
            style={{ display: 'flex' }}
            alt="logout"
            className="widthicon marginbottomimg"
            data-tooltip="tooltip"
            data-placement="right"
            title="Logout"
          />
          <div className="icontext textshadow">Logout</div>
        </div>
        {/* <div className="breakouticonposition" onClick={() => this.goToBreakout()}>
          <img src={breakout} alt="breakout" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Nurses Room" />
          <div className="icontext textshadow">Nurses Room</div>
        </div> */}

        <div className="openchatbtnposition" style={{ right: "1rem" }}>
          <button
            className="chatroombtn"
            onClick={this.openchat}
            id="OpenChat"
          >
            Open Chat
            </button>
          <button
            className="chatroombtn hidebtn"
            onClick={this.closechat}
            id="CloseChat"
          >
            Close Chat
            </button>
        </div>
        <div
          className="presentationmoderator rightchatroom"
          id="moderator1"
        >
          <div className="chat-head">
            <h2>Moderator Message</h2>
          </div>
          <div className="chat-body scrolltype" id="myScrollingChat2">
            <div className="flexform " id="messages2"></div>
            <form
              id="message-form"
              onSubmit={this.sendMessage}
              className="chat-textmoderator"
            >
              <input
                type="text"
                className="message-editbox"
                id="message-txt"
                placeholder="Message..."
                autoComplete="off"
              />
              <button type="submit" className="sendarrow">
                <img className="send-arrow-img" src={SendImg} alt="Send" />
              </button>
            </form>
          </div>
        </div>

      </div>
    );
  }
}
