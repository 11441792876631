import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import analyticsFirebase from '../functions/features/analytics-firebase-app'
import dbFirebaseApp from '../functions/features/db-firebase-app'
import { analyticsFunction, updateEndTime } from '../functions/helpers/analytics-function'
import playImg from '../images/medexIcon/play-med.png';
import pauseImg from '../images/medexIcon/pause-med.png';
// import videoPosterImg from '../images/videoPoster.png';

import Header from '../header/header'
const entranceVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Merz-Emma-Video-final.mp4'
const exteriorIframeSrc = 'https://angles-expo.firebaseapp.com/dekho/60bb1773bc0b71001283414c/0/novr/none'
const expoName = 'aakar-launch';
const roomName = 'exterior'
const sceneName = 'exterior'
export default class Exterior extends Component {

    componentDidMount() {
        if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        var video = document.getElementById('2dVideo');
        // video.addEventListener('timeupdate', this.updateCountdown);
        localStorage.setItem('Location', 'Exterior')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {
            let iframeEl = document.getElementById('exteriorIframe');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        analyticsFunction(roomName)

    }

    updateCountdown = () => {

        var video = document.getElementById('2dVideo');
        var timeSpan = document.querySelector('#countdown span');
        let hours;
        let totalSeconds = video.duration - video.currentTime;
        let minutes;
        let seconds;
        hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        minutes = Math.floor(totalSeconds / 60);
        seconds = totalSeconds % 60;
        // let time = new Date(video.duration - video.currentTime * 1000).toISOString().substr(11, 8);
        timeSpan.innerText = minutes.toString().padStart(2, '0') + ':' + seconds.toFixed().toString().padStart(2, '0');
        console.warn(hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toFixed().toString().padStart(2, '0'))
    }


    playVideo = () => {
        if (document.getElementById('2dVideo') &&
            document.getElementById('Pause2dVideo') &&
            document.getElementById('Play2dVideo') &&
            document.getElementById('startExperienceButton')) {
            document.getElementById('2dVideo').play();
            document.getElementById('2dVideo').volume = 1;
            document.getElementById('Pause2dVideo').style.display = 'block';
            document.getElementById('Play2dVideo').style.display = 'none';
            // document.getElementById('countdown').style.display = 'block';
            setTimeout(() => {
                document.getElementById('startExperienceButton').style.display = 'block';
            }, 100);
        }
    }

    pauseVideo = () => {
        if (document.getElementById('2dVideo') &&
            document.getElementById('Pause2dVideo') &&
            document.getElementById('Play2dVideo')) {
            document.getElementById('2dVideo').pause();

            document.getElementById('2dVideo').volume = 0;
            document.getElementById('Play2dVideo').style.display = 'block'
            document.getElementById('Pause2dVideo').style.display = 'none'
            // document.getElementById('countdown').style.display = 'none';
        }
    }


    addNewEntry = () => {
        if (localStorage.getItem('entryAdded') !== 'false') {
            let Name;
            if (localStorage.getItem('name')) {
                Name = localStorage.getItem('name')
            }
            else {
                Name = ''
            }
            let email;
            if (localStorage.getItem('email')) {
                email = localStorage.getItem('email')
            }
            else {
                email = ''
            }
            let uid;
            if (localStorage.getItem('uid')) {
                uid = localStorage.getItem('uid')
            }
            else {
                uid = ''
            }
            const db = dbFirebaseApp.firestore();
            db.collection("MetaILDLiveUser").add({
                name: Name,
                sceneName: sceneName,
                uid: uid,
                isRaisedHand: 'false',
                email: email,
                startTime: new Date().getTime(),
                expo: 'medex'
            })
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);
                    console.warn('entry added')
                    localStorage.setItem('docRef', docRef.id)
                    localStorage.setItem('entryAdded', false)
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        }
    }

    projectButtonClick = (e) => {
        console.log('exterior click', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey.includes('expo.virtualpropexpo2020.com/lobby')) {
                window.location.href = '/entrance'
            }
        }
    }

    startExperiance = () => {
        document.getElementById('VideoOverlay').style.display = 'none'

        document.getElementById('video-popup').style.display = 'none'
        document.getElementById('2dVideo').setAttribute('src', '');
    }

    render() {
        return (
            <div>
                <Header headerType='user' />
                <div className="overlayVideo" id="VideoOverlay" style={{ display: 'none' }}>
                    <div id="countdown" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div>

                </div>
                <div id="video-popup" style={{ display: 'none' }}>
                    <video id="2dVideo" className="videopopup" playsInline crossOrigin="anonymous" src={entranceVideo} preload="auto" ></video>
                    <button id="Play2dVideo" onClick={this.playVideo} style={{ outline: 'none', border: "none", background: "none" }}>
                        <img src={playImg} alt="play" className="playbtnwidth"></img>
                        <p className="playvideotext">Play Video</p>
                    </button>
                    <button id="Pause2dVideo" style={{ display: 'none', outline: 'none', border: "none", background: "none" }} onClick={this.pauseVideo} >
                        <img src={pauseImg} alt="play" className="playbtnwidth"></img>
                        <p className="playvideotext">Pause Video</p>

                    </button>
                    <button id="startExperienceButton" style={{ display: 'none' }} className="startExperianceBtn heightExperiance" onClick={this.startExperiance} >Enter Event</button>


                </div>
                <iframe id="exteriorIframe" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={exteriorIframeSrc}>
                </iframe>
                <Footer />
            </div>
        )
    }
}
