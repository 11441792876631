import React, { Component } from 'react'
import Footer from '../footer/footer-page'
// import backArrow from '../images/button arrow-1.png';
// import suno from '../images/suno.png'
// import skyNews from '../images/sky news.png'
// import khalijiya from '../images/al khalijiya.png'
// import arebia from '../images/al arebia.png'
// import soma from '../images/93.4 radio soma.png'
// import radioStation from '../images/radioStation.png'
// import skyArebia from '../images/sky-news-arabia.png'
// import radioOff from '../images/radio off.png'
import Header from '../header/header';
import '../functions/helpers/c-zoom-in-out'
// import authFirebaseApp from '../functions/features/auth-firebase-app';
import { isMobileDevice } from '../functions/helpers/extra-functions';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import { analyticsFunction, updateLastSceneEndTime } from '../functions/helpers/analytics-function';
// const breakTimeZoneSrc = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/break+time+zone.jpg'
const sceneName = 'entertainmentZone'
const roomName = 'entertainmentZone'
const radioVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/equi22.mp4';
const radioList = [
    'https://eu8.fastcast4u.com/proxy/clyedupq/?mp=/1', // suno
    'https://stream.radiojar.com/s1mygd1frxquv', // al alberia
    'https://stream.radiojar.com/rzcfw4cbsxquv', // 93.4 radio soma
    'https://radio.skynewsarabia.com/stream/radio/skynewsarabia', // sky news arabia
    'https://stream.radiojar.com/5wpf9e4erxquv', // al khaleejiya
]
const radioListNew = [
    'https://radio.virginradio.co.uk/stream-chilled', // Virgin Radio Chilled //'https://icecast.argovia.ch/vrock', // Virgin Rock
    'https://radio.skynewsarabia.com/stream/radio/skynewsarabia', // sky news arabia
    'https://stream.radiojar.com/5wpf9e4erxquv', // Al Khaleejiya
    'https://l3.itworkscdn.net/smcradiolive/smcradiolive/icecast.audio', // Radio Asia
    'https://stream.radiojar.com/rzcfw4cbsxquv', // Pravasi Bharati News
    'https://streamer.radio.co/s19873af1e/listen' // The Groove Society Radio
]
let myCameraFov = false;
export default class BreakTimeZone extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedbutton: ''
        }
        this.radioPlayerClick = this.radioPlayerClick.bind(this);
        this.goBackToLobby = this.goBackToLobby.bind(this);
    }

    radioPlayerClick(radiosrc) {
        // let sounds = document.getElementsByTagName('audio');
        // for(let i=0; i<sounds.length; i++) 
        // {
        // sounds[i].pause();
        // };

        let radioButton = document.getElementById("radiobuttons")
        radioButton.setAttribute('src', radiosrc)
        radioButton.play()
        // document.getElementById('radioVideo').setAttribute('src', radioVideo)
    }

    stopRadio = () => {
        let radioButton = document.getElementById("radiobuttons")
        radioButton.pause()
        radioButton.currentTime = 0;
        // document.getElementById('radioVideo').setAttribute('src', '')
        // document.getElementById('radioVideo').stop();
    }

    goBackToLobby() {
        this.props.goToLobby();
    }


    componentDidMount() {
        if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        localStorage.setItem('Location', 'entertainment-zone')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {

            let iframeEl = document.getElementById('newEntertainmentPageBackground');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: 'medex'
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        // analyticsFunction(roomName);
        updateLastSceneEndTime(roomName)
        // this.updateCollection();
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }

    projectButtonClick = (e) => {

        console.log('called on entertainment arrow', e.data)
        if (e.data.isImageClicked) {
            // window.desktopDialog=true
            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            } else if(e.data.mslKey.includes('stopradio')) {
                this.stopRadio();
            }
        }

        if (e.data.isLinkClicked) {
            console.warn('Entertainment button click', e.data.url);
            if (e.data.url.includes('radiostation1')) {
                this.radioPlayerClick(radioListNew[0]);
            } else if (e.data.url.includes('radiostation2')) {
                this.radioPlayerClick(radioListNew[1]);
            } else if (e.data.url.includes('radiostation3')) {
                this.radioPlayerClick(radioListNew[2]);
            } else if (e.data.url.includes('radiostation4')) {
                this.radioPlayerClick(radioListNew[3]);
            } else if (e.data.url.includes('radiostation5')) {
                this.radioPlayerClick(radioListNew[4]);
            } else if (e.data.url.includes('radiostation6')) {
                this.radioPlayerClick(radioListNew[5]);
            } else if (e.data.url.includes('stopradiostation')) {
                this.stopRadio();
            }
        }
    }

    // updateCollection = () => {
    //     const db = dbFirebaseApp.firestore();
    //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
    //         sceneName: sceneName,
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", docRef);
    //             // currentDoc = docRef;
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }

    onTouchStart = (event) => {
        if (isMobileDevice() && myCameraFov === false) {
            if (document.querySelector('#entertainmentCamera')) {
                document.querySelector('#entertainmentCamera').setAttribute('fov', '55');
                setTimeout(() => {
                    document.querySelector('#entertainmentCamera').setAttribute('fov', '60');
                }, 100);
                myCameraFov = true;
            }
        }
    }

    render() {
        return (
            <div>
                <Header headerType="user" />
                <audio style={{ visibility: 'hidden' }} controls src="" id="radiobuttons" />
                {/* <a-scene loading-screen="enabled: false" vr-mode-ui="enabled: false" style={{ zIndex: 2 }}>
                        <a-assets>
                            <img id="sky" crossOrigin="anonymous" src={breakTimeZoneSrc} alt="background" />
                            <img id="backArrowentertainment" src={backArrow} alt='back' />
                            <img id="src1" src={suno} alt='radio button' />
                            <img id="src2" src={skyNews} alt='radio button' />
                            <img id="src3" src={khalijiya} alt='radio button' />
                            <img id="src4" src={arebia} alt='radio button' />
                            <img id="src5" src={soma} alt='radio button' />
                            <img id="src6" src={radioStation} alt='radio button' />
                            <img id="src7" src={skyArebia} alt='radio button' />
                            <img id="stopradio" src={radioOff} alt='stop radio button' />
                            <video id="radioVideo" autoPlay loop="true" playsInline crossOrigin="anonymous"></video>
                            <video id="radioVideoback" autoPlay loop="true" src={radioVideo} playsInline crossOrigin="anonymous"></video>
                        </a-assets>
                        <a-camera c-zoom-in-out look-controls="gyroModeEnabled: false; magicWindowTrackingEnabled: false; reverseTouchDrag: true;" id="camera" fov="50" wasd-controls="enabled:false">
                            <a-entity
                                cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
                                raycaster="objects: .raycaster-solid; recursive: false;"
                            />
                        </a-camera>

                        <a-sky src="#sky" rotation="0 -81.5 0" sky-entertainment-component></a-sky>
                        <a-image src="#backArrowentertainment" look-at="#camera" id="entertainment-back" position="-17.366815402059938 2.4003912210523413 9.633424676394954" height="1.6785353086425965" width="1.6738351171788868" class="raycaster-solid clickable" onClick={this.goBackToLobby}></a-image>
                        <a-image src="#stopradio" look-at="#camera" position="0.696 3.361 -32.50" scale="2 2 2"

                            class="raycaster-solid clickable" onClick={this.stopRadio}></a-image>
                        <a-image src="#src1" position="-6.36 5.861 -32.50" scale="2 2 2"
                            look-at="#camera"
                            id="radio1" class="raycaster-solid clickable" onClick={() => this.radioPlayerClick(radioList[0])}></a-image>
                        <a-image src="#src2" position="-3.10 5.861 -32.50" scale="2 2 2"

                            look-at="#camera" id="radio2" class="raycaster-solid clickable" onClick={() => this.radioPlayerClick(radioList[1])}></a-image>
                        <a-image src="#src3" position="0.696 5.861 -32.50" scale="2 2 2"

                            look-at="#camera" id="radio3" class="raycaster-solid clickable" onClick={() => this.radioPlayerClick(radioList[2])}></a-image>
                        <a-image src="#src4" position="4.206 5.861 -32.50" scale="2 2 2"

                            look-at="#camera" id="radio4" class="raycaster-solid clickable" onClick={() => this.radioPlayerClick(radioList[3])}></a-image>
                        <a-image src="#src5" position="7.800 5.861 -32.50" scale="2 2 2"

                            look-at="#camera" id="radio5" class="raycaster-solid clickable" onClick={() => this.radioPlayerClick(radioList[4])}></a-image>
                        <a-image src="#src6" look-at="#camera"
                            position="0.696 7.881 -32.50" scale="6 2 2"
                            id="radio6" ></a-image>
                        <a-video src="#radioVideo" id="radioVideo1" position="6.6 2.25 -14.6" rotation="0 -45 0" scale="4 3 3" ></a-video>
                        <a-video src="#radioVideo" id="radioVideo2" position="-6.0 2.25 -14.6" rotation="0 45 0" scale="4 3 3" ></a-video>
                        <a-video src="#radioVideoback" id="radioVideo3" position="1.0 2.10 19.97" rotation="0 0 0" scale="5 3 3" ></a-video>
                    </a-scene> */}
                <iframe id="newEntertainmentPageBackground" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src="https://angles-expo.firebaseapp.com/dekho/605d975ff0b38200129ac8ef/0/novr/none">
                </iframe>
                <Footer />
            </div>
        )
    }
}
