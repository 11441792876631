import React, { Component } from 'react';
import './header.css';

import logoImg from '../images/logo.png';
import medexLogo from '../images/med.png';
import whiteLogo from '../images/logo.png'
import whiteBLogo from '../images/logo.png'

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerType: this.props.headerType
        }
        // this.logoBtn = this.logoBtn.bind(this);
    }

    componentDidMount() {
        console.warn('user role', this.state.headerType)
    }

    // logoBtn = () => {
    //     window.location.href = '/';
    // }

    // goBackToLobby=()=>{
    //     window.location.href='/lobby-page'
    // }


    render() {
        return (
            <div>
                {this.state.headerType === 'user' &&
                    <div>
                        {/* angles logo */}
                        <div className="logopositionleft">
                            <div className="flexlogo">
                                <img src={medexLogo} className="widthangleslogo" alt="" />
                            </div>
                        </div>
                        {/* boehringer logo */}
                        {/* <div className="logopositionright">
                            <div className="flexlogo">
                                <img src={logoImg} className="widthboihringerlogo" alt="" />
                            </div>
                        </div> */}
                    </div>
                }
                {this.state.headerType === 'speaker' &&

                    <div>
                        <div className="logopositionleft">
                            <div className="flexlogo">
                                <img src={whiteLogo} className="widthangleslogo" alt="" />
                            </div>
                        </div>
                        <div className="logopositionright">
                            <div className="flexlogo">
                                <img src={whiteBLogo} className="widthboihringerlogo" alt="" />
                            </div>
                        </div>
                    </div>
                }
                {this.state.headerType === 'moderator' &&
                    <div>
                        <div className="logopositionleft">
                            <div className="flexlogo">
                                <img src={whiteLogo} className="widthangleslogo" alt="" />
                            </div>
                        </div>
                        {/* <div className="logopositionright"> */}
                            {/* <div className="flexlogo"> */}
                                {/* <img src={whiteBLogo} className="widthboihringerlogo" alt="" /> */}
                            {/* </div> */}
                        {/* </div> */}
                    </div>} 

            </div>
        )
    }
}
