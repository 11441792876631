export function isMobileDevice() {
  if (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)) {
    return true;
  } else {
    return false;
  }
}

export function isAlreadyExistInArray(existingArray, uidToCheck) {
  let isFound = false;
  for (let i = 0; i < existingArray.length; i++) {
    if (existingArray[i].uid === uidToCheck) {
      isFound = true;
      break;
    }
  }
  return isFound;
}

export function removeEntry(existingArray, uidToremove) {
  let newArray = []
  for (let i = 0; i < existingArray.length; i++) {
    if (existingArray[i].uid !== uidToremove) {
      newArray.push(existingArray)
    }
  }
  return newArray;
}

export function SetAllImages(currentArray, entityInitialName) {
  for (let i = 0; i < currentArray.length; i++) {
    // currentArray[i].position = getMyLocation(i);
    if (document.getElementById(entityInitialName + currentArray[i].uid)) {
      document.getElementById(entityInitialName + currentArray[i].uid).setAttribute('position', getMyLocation(i));
    } else {
      console.warn('No Element found for', entityInitialName + currentArray[i].uid)
    }
  }


}

function getMyLocation(indexNum) {
  let myPosition = '';
  switch (indexNum) {
    case 0:
      myPosition = '0.6 2.41 -7';
      break;
    case 1:
      myPosition = '1.65 2.415 -7';
      break;
    case 2:
      myPosition = '2.7 2.43 -7';
      break;
    case 3:
      myPosition = '3.75 2.46 -7';
      break;
    case 4:
      myPosition = '0.6 1.5 -7';
      break;
    case 5:
      myPosition = '1.65 1.55 -7';
      break;
    case 6:
      myPosition = '2.7 1.65 -7';
      break;
    case 7:
      myPosition = '3.75 1.5 -7';
      break;
    default:
      myPosition = '1.5 3 -7';
      break;
  }
  return myPosition;
}
