import React, { Component } from 'react'
import Footer from '../footer/footer-page'
// import backArrow from '../images/button arrow-1.png';
// import suno from '../images/suno.png'
// import skyNews from '../images/sky news.png'
// import khalijiya from '../images/al khalijiya.png'
// import arebia from '../images/al arebia.png'
// import soma from '../images/93.4 radio soma.png'
// import radioStation from '../images/radioStation.png'
// import skyArebia from '../images/sky-news-arabia.png'
// import radioOff from '../images/radio off.png'
import Header from '../header/header';
import '../functions/helpers/c-zoom-in-out'
// import authFirebaseApp from '../functions/features/auth-firebase-app';
import { isMobileDevice } from '../functions/helpers/extra-functions';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import { analyticsFunction, updateLastSceneEndTime } from '../functions/helpers/analytics-function';
// const breakTimeZoneSrc = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/break+time+zone.jpg'
const sceneName = 'medex-faculty'
const roomName = 'medex-faculty'
const radioVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/equi22.mp4';
const radioList = [
    'https://eu8.fastcast4u.com/proxy/clyedupq/?mp=/1', // suno
    'https://stream.radiojar.com/s1mygd1frxquv', // al alberia
    'https://stream.radiojar.com/rzcfw4cbsxquv', // 93.4 radio soma
    'https://radio.skynewsarabia.com/stream/radio/skynewsarabia', // sky news arabia
    'https://stream.radiojar.com/5wpf9e4erxquv', // al khaleejiya
]
let myCameraFov = false;
export default class MedexFaculty extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedbutton: ''
        }
        this.radioPlayerClick = this.radioPlayerClick.bind(this);
        this.goBackToLobby = this.goBackToLobby.bind(this);
    }

    radioPlayerClick(radiosrc) {
        // let sounds = document.getElementsByTagName('audio');
        // for(let i=0; i<sounds.length; i++) 
        // {
        // sounds[i].pause();
        // };

        let radioButton = document.getElementById("radiobuttons")
        radioButton.setAttribute('src', radiosrc)
        radioButton.play()
        // document.getElementById('radioVideo').setAttribute('src', radioVideo)
    }

    stopRadio = () => {
        let radioButton = document.getElementById("radiobuttons")
        radioButton.pause()
        radioButton.currentTime = 0;
        // document.getElementById('radioVideo').setAttribute('src', '')
        // document.getElementById('radioVideo').stop();
    }

    goBackToLobby() {
        this.props.goToLobby();
    }


    componentDidMount() {
        localStorage.setItem('Location', 'entertainment-zone')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {

            let iframeEl = document.getElementById('medexFaculty');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: 'medex'
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        // this.updateCollection();
        updateLastSceneEndTime(roomName);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }

    projectButtonClick = (e) => {

        console.log('called on entertainment arrow')
        if (e.data.isImageClicked) {
            console.log('called on lobby arrow')
            // window.desktopDialog=true
            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            }
        }

        if (e.data.isLinkClicked) {
            console.warn('Entertainment button click', e.data.url);
            if (e.data.url === 'radiostation1') {
                this.radioPlayerClick(radioList[0]);
            } else if (e.data.url === 'radiostation2') {
                this.radioPlayerClick(radioList[1]);
            } else if (e.data.url === 'radiostation3') {
                this.radioPlayerClick(radioList[2]);
            } else if (e.data.url === 'radiostation4') {
                this.radioPlayerClick(radioList[3]);
            } else if (e.data.url === 'radiostation5') {
                this.radioPlayerClick(radioList[4]);
            } else if (e.data.url === 'stopradiostation') {
                this.stopRadio();
            }
        }
    }

    // updateCollection = () => {
    //     const db = dbFirebaseApp.firestore();
    //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
    //         sceneName: sceneName,
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", docRef);
    //             // currentDoc = docRef;
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }

    onTouchStart = (event) => {
        if (isMobileDevice() && myCameraFov === false) {
            if (document.querySelector('#entertainmentCamera')) {
                document.querySelector('#entertainmentCamera').setAttribute('fov', '55');
                setTimeout(() => {
                    document.querySelector('#entertainmentCamera').setAttribute('fov', '60');
                }, 100);
                myCameraFov = true;
            }
        }
    }

    render() {
        return (
            <div>
                <Header headerType="user" />
                {/* <audio style={{ visibility: 'hidden' }} controls src="" id="radiobuttons" /> */}
                
                <iframe id="medexFaculty" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src="https://angles-expo.firebaseapp.com/dekho/6067254f285af500113f2107/0/novr/none">
                </iframe>
                <Footer />
            </div>
        )
    }
}
