/* eslint-disable */

import loginFirebaseApp from "../functions/features/login-firebase-app";


let api;
// let moderatorApi;
let userJWT = '';
// let moderatorJwt = ''
let tenant = '';
const expoName = 'aakar-launch'


export function updateJwtAndTenant(roomName) {
  console.warn('onload function')
  const db = loginFirebaseApp.firestore();
  const userCollection = db.collection('LiveEvents');
  let ExistingUserQuery = new Promise((resolve, reject) => {
    userCollection.where('expo', '==', expoName)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());
          let myData = doc.data();
          myData.id = doc.id;
          resolve(myData);
        });
        resolve('Not Found');
      })
      .catch(function (error) {
        reject(error);
      });
  });
  ExistingUserQuery.then(result => {
    if (result === 'Not Found') {
      console.warn('not found')
    } else {
      // commonFunction(PAGETYPE.LOBBY);

      if (result) {
        userJWT = result.jwtUser;
        //   moderatorJwt = result.jwt;
        tenant = result.tenant;
        console.warn('userjwt ', userJWT)
        //   console.warn('moderator ', moderatorJwt)
        console.warn('tenant ', tenant)
        StartJitsiMeeting(roomName)


      } else {
        console.warn('not found')
      }
    }
  }).catch(error => {
    console.error('Already existing user check error', error);
  });
}

function StartJitsiMeeting(roomName) {
  const domain = '8x8.vc';
  const options = {
    roomName: tenant + '/' + roomName, //'vpaas-magic-cookie-88dfb7a7488f43a689e37d432fe97a85/test'
    width: '100%',
    height: '100%',
    moderator: 'false',
    parentNode: document.querySelector('#meet'),
    jwt: userJWT,
    interfaceConfigOverwrite: {
      TOOLBAR_ALWAYS_VISIBLE: false,
      HIDE_INVITE_MORE_HEADER: true,
      SHOW_WATERMARK_FOR_GUESTS: true,
      TOOLBAR_TIMEOUT: 10000,
      INITIAL_TOOLBAR_TIMEOUT: 5000,
      SHOW_CHROME_EXTENSION_BANNER: false,
      VIDEO_QUALITY_LABEL_DISABLED: true,
      ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 5000,
      DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      HIDE_KICK_BUTTON_FOR_GUESTS: true,
      FILM_STRIP_MAX_HEIGHT: 120,
      DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
      DISABLE_FOCUS_INDICATOR: true,
      TILE_VIEW_MAX_COLUMNS: 5,
      TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'fullscreen', 'raisehand',
        'filmstrip', 'desktop', 'chat', 'settings',
        'tileview', 'hangup', 'select-background', 'mute-everyone', 'mute-video-everyone'
      ],
    },
  };
  api = new JitsiMeetExternalAPI(domain, options);

  api.addEventListener('videoConferenceLeft', function (meetingObj) {
    console.log('Leaving meeting', meetingObj.roomName);
    window.location.href = '/entrance';
  })
}
