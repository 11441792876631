import { createBrowserHistory } from 'history'
import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import AgendaPage from '../agenda/agenda-page'
import ArchiveGallary from '../archive-gallary/archive-gallary-page'
import ArchiveGallary1 from '../archive-gallary/archive-gallary-page1'
import ArchiveGallary2 from '../archive-gallary/archive-gallary-page2'
import BreakTimeZone from '../break-time-zone/break-time-zone-page'
// import BreakoutConferance from '../breakout/breakout-conferance'
// import BreakoutPage2 from '../breakout/breakout-page-2'
// import BreakoutPage3 from '../breakout/breakout-page-3'
// import BreakoutPage4 from '../breakout/breakout-page-4'
// import BreakoutPage5 from '../breakout/breakout-page-5'
// import BreakoutPage6 from '../breakout/breakout-page-6'

// import DisplayGallaryPage from '../display-gallary/display-gallery-page'
// import DisplayGallaryPage1 from '../display-gallary/display-gallery-page1'
// import DisplayGallaryPage2 from '../display-gallary/display-gallery-page2'
// import DisplayGallaryPage3 from '../display-gallary/display-gallery-page3'
// import DisplayGallaryPage4 from '../display-gallary/display-gallery-page4'
// import DisplayGallaryPage5 from '../display-gallary/display-gallery-page5'
// import DisplayGallaryPage6 from '../display-gallary/display-gallery-page6'
// import DisplayGallaryPage7 from '../display-gallary/display-gallery-page7'
// import DisplayGallaryPage8 from '../display-gallary/display-gallery-page8'
import DisplayPage from '../display-gallary/display-page'
import Entrance from '../entrance/entrance'
import MedexFaculty from '../entrance/medex-faculty'
import Exterior from '../exterior/exterior'
import InfoPage from '../info/info-page'
import LobbyPage from '../lobby/lobby-page'
import LoginPage from '../login/login-page'
import RootComponent from '../login/root-component'
// import ModeratorBreakout1 from '../moderator/moderatorBreakout1'
// import ModeratorBreakout2 from '../moderator/moderatorBreakout2'
// import ModeratorBreakout3 from '../moderator/moderatorBreakout3'
// import ModeratorBreakout4 from '../moderator/moderatorBreakout4'
// import ModeratorBreakout5 from '../moderator/moderatorBreakout5'
// import ModeratorBreakout6 from '../moderator/moderatorBreakout6'
import ModeratorWebinar from '../moderator/moderatorWebinar'
import ModeratorSalesPage from '../sales/moderator-sales-page'
import SalesPage from '../sales/sales-page'
import SpeakerWebinar from '../speaker/speakerWebinar'
import webinarHall2D from '../webinar-hall/webinar-hall-2d'
import WebinarHallPage from '../webinar-hall/webinar-hall-page'

export default class RouterContainer extends Component {
    render() {
        return (
            <div>
                 <Router history={createBrowserHistory}>
                    <Switch>
                        <Route exact path="/" component={LoginPage} />
                        <Route exact path="/main/:uid" component={RootComponent} />
                        <Route exact path="/exterior" component={Exterior} />
                        <Route exact path="/entrance" component={ArchiveGallary} />
                        {/* <Route exact path="/agenda" component={AgendaPage} /> */}
                        <Route exact path="/archive" component={ArchiveGallary} />
                        <Route exact path="/archive1" component={ArchiveGallary1} />
                        <Route exact path="/archive2" component={ArchiveGallary2} />
                        <Route exact path="/faculty-room" component={MedexFaculty} />
                        {/* <Route exact path="/breakout-page1" component={BreakoutPage} />
                        <Route exact path="/breakout-page2" component={BreakoutPage2} />
                        <Route exact path="/breakout-page3" component={BreakoutPage3} />
                        <Route exact path="/breakout-page4" component={BreakoutPage4} />
                        <Route exact path="/breakout-page5" component={BreakoutPage5} />
                        <Route exact path="/breakout-page6" component={BreakoutPage6} /> */}
                        <Route exact path="/entertainment-zone" component={BreakTimeZone} />
                        <Route exact path="/product-launch" component={DisplayPage} />
                        {/* <Route exact path="/country" component={DisplayGallaryPage} />
                        <Route exact path="/country1" component={DisplayGallaryPage1} />
                        <Route exact path="/country2" component={DisplayGallaryPage2} />
                        <Route exact path="/country3" component={DisplayGallaryPage3} />
                        <Route exact path="/country4" component={DisplayGallaryPage4} />
                        <Route exact path="/country5" component={DisplayGallaryPage5} />
                        <Route exact path="/country6" component={DisplayGallaryPage6} />
                        <Route exact path="/country7" component={DisplayGallaryPage7} />
                        <Route exact path="/country8" component={DisplayGallaryPage8} /> */}
                        {/* <Route exact path="/info-page" component={InfoPage} /> */}
                        {/* <Route exact path="/lobby-page" component={LobbyPage} /> */}
                        <Route exact path="/webinar-hall" component={WebinarHallPage} />
                        <Route exact path="/webinar" component={webinarHall2D} />
                        {/* <Route exact path="/moderator-breakout1" component={ModeratorBreakout1} />
                        <Route exact path="/moderator-breakout2" component={ModeratorBreakout2} />
                        <Route exact path="/moderator-breakout3" component={ModeratorBreakout3} />
                        <Route exact path="/moderator-breakout4" component={ModeratorBreakout4} />
                        <Route exact path="/moderator-breakout5" component={ModeratorBreakout5} />
                        <Route exact path="/moderator-breakout6" component={ModeratorBreakout6} /> */}
                        <Route exact path="/moderator-webinar" component={ModeratorWebinar} />
                        <Route exact path="/speaker-webinar" component={SpeakerWebinar} />
                        <Route exact path="/live-chat" component={SalesPage} />
                        <Route exact path="/moderator-live-chat" component={ModeratorSalesPage} />
                        
                    </Switch>
                </Router>
            </div>
        )
    }
}
