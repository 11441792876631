import React, { Component } from "react";
import $ from "jquery";
import "./videojs.css";
import "./moderator-page.css";
import "../webinar-hall/webinar-hall-page.css";
import "./chat-room.css";
import {
  loadAttendeeMessages,
  loadModeratorMessages,
  saveMessageToDatabaseforModerator,
  saveMessageToDatabase,
  saveMessageToDatabaseAdmin,
} from "../functions/features/chat/chat-functions";
import videojs from "video.js";
import SendImg from "../images/send.png";
import logout from "../images/medexIcon/logout.png";
import participants from "../images/participants.png";
import closebtn from "../images/cross-2.png";
import raisehandicon from "../images/lower hand.png";
import rotatemobile from "../images/rotate-mobile.png";
import LoadingPageBtn from "../loading/loading-page-btn";
import breakout from "../images/breakout-icon.png";
// import Footer from '../helpers/footer/footer';
// import Header from '../helpers/header/header';
import CustomNotification from "../helpers/notification/notification";
// import { StartMeeting, StartWebinarMeeting } from '../functions/helpers/webinar-zoom';
// import { StartSpeakerWebinarMeeting } from '../functions/helpers/webinar-zoom-2d';
import authFirebaseApp from "../functions/features/auth-firebase-app";
import { analyticsFunction } from "../functions/helpers/analytics-function";
import Header from "../header/header";
import dbFirebaseApp from "../functions/features/db-firebase-app";
import fullscreenButton from "../images/medexIcon/merz-fullscreen.png";
import zoomInButton from "../images/medexIcon/zoom-in.png";
import zoomOutButton from "../images/medexIcon/zoom-out.png";
import salesRoom from "../images/medexIcon/sales-room.png";
import loginFirebaseApp from "../functions/features/login-firebase-app";
const liveFormat = "application/x-mpegURL";
let shouldFullscreenHidden = false;
const roomName = "moderatorWebinar";
const normalImage =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/aakar-launch-webinar-bg.jpg";
const zoomedInImage =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/aakar-launch-webinar-bg-zoom-in.jpg";

const eventPoster = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/aakar-launch-event-poster.jpg";
const eventThankYouPoster = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/aakar-launch-thank-you-poster.jpg";

const expoName = 'aakar-launch';
let isZoomIn = false;
export default class ModeratorWebinar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRaisedHandNotification: false,
      raiseHandMessage: "",
      isloading: true,
      isBtnShow: false,
    };

    this.logOut = this.logOut.bind(this);
  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = "";
    console.log("on unload called");
  };

  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "moderator" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");
    } else {
      // window.location.href = '/'
    }
    videojs("my-video").src({
      // type: liveFormat,
      src: "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Aakar-Launch-Live-Session.mp4",
    });
    // authFirebaseApp.auth().onAuthStateChanged((user) => {
    //     if ((user) && (localStorage.getItem('userRole') === 'moderator')) {
    //         console.warn('user moderator-webinar', user)
    //         // ...
    //     }
    //     else {
    //         console.warn('user not found')
    //         window.location.href = '/'
    //         // User is signed out
    //         // ...
    //     }
    // });
    // loadAttendeeMessages('webinarGulf');
    loadAttendeeMessages("webinarAakarLaunch");
    // loadModeratorMessages("moderatorRoomUdyogLaunch");
    // StartSpeakerWebinarMeeting(1);
    // this.listenForWebinarChanges();

    // window.onbeforeunload = () =>{
    //     console.log("moderator page is about to unload")
    // }
    // window.addEventListener("beforeunload", this.onUnload);
    this.setState({
      isBtnShow: true,
    });
    this.getUserDetails();

    $(function () {
      var arrow = $(".chat-head img");

      arrow.on("click", function () {
        var src = arrow.attr("src");

        $(".chat-body").slideToggle("fast");
        if (
          src ===
          "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
        ) {
          arrow.attr(
            "src",
            "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
          );
        } else {
          arrow.attr(
            "src",
            "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
          );
        }
        videojs("my-video").src({
          // type: liveFormat,
          src: "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Aakar-Launch-Live-Session.mp4",
        });

        // resolve(profilePicArray);
      });
    });
  }

  getUserDetails() {
    let self = this;
    const db = loginFirebaseApp.firestore();
    const userCollection = db.collection('LiveEvents');
    userCollection.where('expo', '==', expoName)
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {

          if (change.doc.data().marquee === true) {
            self.setState({
              isMarqueeShow: true,
              marqueeText: change.doc.data().marqueeText
            })
            console.warn('marquee state is ::', self.state.isMarqueeShow)
          } else if (change.doc.data().marquee === false) {
            self.setState({ isMarqueeShow: false })
            console.warn('marquee state is ::', self.state.isMarqueeShow)
          }

          if (change.doc.data().eventPoster === true) {

            document.getElementById('posterForWebinar').style.display = 'block';
            document.getElementById('my-video').style.display = 'none';

            if (change.doc.data().thankyouPoster === true) {
              document.getElementById('posterForWebinar').src = eventThankYouPoster;
            } else {
              document.getElementById('posterForWebinar').src = eventPoster;
            }

            videojs("my-video").src({
              // type: liveFormat,
              src: "",
            });

            videojs("my-video").pause();
            videojs("my-video").volume = 0;
            document.getElementById('fullScreenButtonPosition').style.display = 'none';
            shouldFullscreenHidden = true;


          } else if (change.doc.data().eventPoster === false) {
            document.getElementById('posterForWebinar').style.display = 'none';
            document.getElementById('my-video').style.display = 'block';

            videojs("my-video").src({
              // type: liveFormat,
              src: "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Aakar-Launch-Live-Session.mp4",
            });

            videojs("my-video").play();
            videojs("my-video").volume = 1;
            document.getElementById('fullScreenButtonPosition').style.display = 'block';
            shouldFullscreenHidden = false;

          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            console.log("New city: ", change.doc.data());
            if (change.doc.data().eventPoster === true) {
              document.getElementById('posterForWebinar').style.display = 'block';
              document.getElementById('my-video').style.display = 'none';

              if (change.doc.data().thankyouPoster === true) {
                document.getElementById('posterForWebinar').src = eventThankYouPoster;
              } else {
                document.getElementById('posterForWebinar').src = eventPoster;
              }
              videojs("my-video").src({
                // type: liveFormat,
                src: "",
              });

              videojs("my-video").pause();
              videojs("my-video").volume = 0;
              document.getElementById('fullScreenButtonPosition').style.display = 'none';
              shouldFullscreenHidden = true;

            } else if (change.doc.data().eventPoster === false) {
              document.getElementById('posterForWebinar').style.display = 'none';
              document.getElementById('my-video').style.display = 'block';

              videojs("my-video").src({
                // type: liveFormat,
                src: "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Aakar-Launch-Live-Session.mp4",
              });

              videojs("my-video").play();
              videojs("my-video").volume = 1;
              document.getElementById('fullScreenButtonPosition').style.display = 'block';
              shouldFullscreenHidden = false;

            }
          }
        });
      });
  }


  goToBreakout1 = () => {
    window.location.href = "/moderator-breakout1";
  };

  goToBreakout2 = () => {
    window.location.href = "/moderator-breakout2";
  };

  goToBreakout3 = () => {
    window.location.href = "/moderator-breakout3";
  };

  goToBreakout4 = () => {
    window.location.href = "/moderator-breakout4";
  };

  goToBreakout5 = () => {
    window.location.href = "/moderator-breakout5";
  };

  goToBreakout6 = () => {
    window.location.href = "/moderator-breakout6";
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  logOut() {
    localStorage.clear();
    window.location.href = "/";
    // authFirebaseApp.auth().signOut().then(res => {
    //     console.log('log out')
    // })
    // this.props.goToPage(PAGETYPE.ENTERTAINMENT);
  }

  openSalesBooth = () => {
    window.location.href = "/moderator-live-chat";
  }

  sendMessage = (e) => {
    e.preventDefault();
    const msg = document.getElementById("message-txt").value;
    if (msg !== "") {
      // console.warn('Sending', msg);
      saveMessageToDatabaseforModerator(msg, "moderatorRoomUdyogLaunch");
    } else {
      console.warn("Button should be disabled now");
    }
  };
  sendMessageAdmin = (e) => {
    e.preventDefault();
    const msg = document.getElementById("message-txt-1").value;
    if (msg !== "") {
      // console.warn('Sending', msg);
      saveMessageToDatabaseAdmin(msg, "webinarAakarLaunch");
    } else {
      console.warn("Button should be disabled now");
    }
  };
  openchat = () => {
    document.getElementById("moderator").classList.add("bottomtotop");
    document.getElementById("moderator1").classList.add("bottomtotop");
    document.getElementById("message-form").classList.add("bottomtotop");
    document.getElementById("message-form-1").classList.add("bottomtotop");
    document.getElementById("CloseChat").classList.remove("hidebtn");
    document.getElementById("OpenChat").classList.add("hidebtn");
  };
  closechat = () => {
    document.getElementById("moderator").classList.remove("bottomtotop");
    document.getElementById("moderator1").classList.remove("bottomtotop");
    document.getElementById("message-form").classList.remove("bottomtotop");
    document.getElementById("message-form-1").classList.remove("bottomtotop");
    document.getElementById("OpenChat").classList.remove("hidebtn");
    document.getElementById("CloseChat").classList.add("hidebtn");
  };
  openparticipant = () => {
    document
      .getElementById("partcipant")
      .classList.toggle("bottomtotopparticipant");
  };
  changeStateForLoader = (value) => {
    // document.getElementById('video-player').play();

    // videojs('video-player').play();
    // videojs('video-player1').play();
    // videojs('video-player1').volume(0);

    this.setState({ isloading: value });

    videojs("my-video").src({
      // type: liveFormat,
      src: "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Aakar-Launch-Live-Session.mp4",
    });
    videojs("my-video").play();
  };
  fullscreenVideo = () => {
    let videoElement = document.getElementById("my-video");
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) {
      /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      /* IE/Edge */
      videoElement.msRequestFullscreen();
    } else if (videoElement.webkitSupportsFullscreen) {
      videoElement.webkitEnterFullscreen();
    }
  };

  toggleZoomButton = () => {
    if (isZoomIn) {
      this.zoomOutMethod();
    } else {
      this.zoomInMethod();
    }
    isZoomIn = !isZoomIn;
  };

  zoomInMethod = () => {
    document.getElementById("zoomButton").src = zoomOutButton;
    document.getElementById("zoomButton").title = "Zoom Out";
    document.getElementById("breakout2dimg").src = zoomedInImage;
    document.getElementById("mainContainer").style.width = "50.5%";
    document.getElementById("mainContainer").style.height = "51%";
    document.getElementById("mainContainer").style.top = "45.7%";
    document.getElementById("mainContainer").style.left = "49.95%";
    if (!this.isMobileDevice()) {
      document.getElementById("fullscreenButton").style.width = "62px";
      document.getElementById("zoomButton").style.width = "62px";
      document.getElementById("zoomButtonPosition").style.right = "-72px";
      document.getElementById("fullScreenButtonPosition").style.right = "-72px";
    }
  };

  zoomOutMethod = () => {
    document.getElementById("zoomButton").src = zoomInButton;
    document.getElementById("zoomButton").title = "Zoom In";
    document.getElementById("breakout2dimg").src = normalImage;
    document.getElementById("mainContainer").removeAttribute("style");
    document.getElementById("fullscreenButton").removeAttribute("style");
    document.getElementById("zoomButton").removeAttribute("style");
    document.getElementById("zoomButtonPosition").removeAttribute("style");
    document.getElementById("fullScreenButtonPosition").removeAttribute("style");

    let iPhoneDevices = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    if (iPhoneDevices) {
      document.getElementById('fullScreenButtonPosition').style.display = 'none';
    }

    if (shouldFullscreenHidden) {
      document.getElementById('fullScreenButtonPosition').style.display = 'none';
    }
  };
  render() {
    return (
      <div>
        <Header headerType="moderator" />
        {this.state.isloading && (
          <LoadingPageBtn
            updateStateForLoader={this.changeStateForLoader}
            btnStatus={this.state.isBtnShow}
          />
        )}
        {this.state.isRaisedHandNotification && (
          <CustomNotification msg={this.state.raiseHandMessage} />
        )}
        {/* <div className="flexmoderator"> */}
        <div className="fullScreen">
          <div id="mainContainer" className="video-container">
            <img id="posterForWebinar" className="video-width" src={eventPoster} alt="poster" />
            <video
              id="my-video"
              className="video-js video video-width"
              preload="auto"
              playsInline
              webkitplaysInline
              webkit-playsInline
              crossOrigin="anonymous"
              data-setup="{}"
              onClick={this.fullscreenVideo}
            >
              <source
                crossorigin="anonymous"
                src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/Aakar-Launch-Live-Session.mp4"
                type="video/mp4"
              />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                >
                  supports HTML5 video
                </a>
              </p>
            </video>
            <script src="https://vjs.zencdn.net/7.8.4/video.js"></script>
            <div className="fullscreen-position" id="fullScreenButtonPosition">
              <img
                id="fullscreenButton"
                alt="fullscreen"
                title="Full Screen"
                className="iconwidthfullscreen"
                src={fullscreenButton}
                onClick={this.fullscreenVideo}
              />
            </div>
            <div className="zoom-position" id="zoomButtonPosition">
              <img
                id="zoomButton"
                alt="fullscreen"
                title="Zoom In"
                className="iconwidthzoom"
                src={zoomInButton}
                onClick={this.toggleZoomButton}
              />
            </div>
          </div>

          <img
            id="breakout2dimg"
            src={normalImage}
            crossOrigin="anonymous"
            className="Breakout2dImg"
            alt="background"
          />
          {/* <div className="leftbannerposition">
                        <img id="screenbg" alt="screen" src={gifbgscreen} className="bgwidth" />
                        <div className="leftgifposition">
                            <img id="leftScreenGif" alt="screen" crossOrigin="anonymous" src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-webinar-full.gif" />
                        </div>
                    </div>
                    <div className="rightbannerposition" style={{ display: 'none' }}>
                        <img id="screenbg" alt="screen" src={gifbgscreen} className="bgwidth" />
                        <div className="leftgifposition">
                            <img id="leftScreenGif" alt="screen" crossOrigin="anonymous" src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-webinar-full.gif" />
                        </div>
                    </div> */}
        </div>
        <div className="overlaylandscape-1">
          <img src={rotatemobile} className="rotateimg-1" />
          <p className="rotatetext-1">
            Rotate your device to join Breakout Room
          </p>
        </div>
        <div className="moderatorposition">
          <div className="openchatbtnposition" style={{ right: "1rem" }}>
            <button
              className="chatroombtn "
              onClick={this.openchat}
              id="OpenChat"
            >
              Open Chat
            </button>
            <button
              className="chatroombtn hidebtn "
              onClick={this.closechat}
              id="CloseChat"
            >
              Close Chat
            </button>
          </div>
          <div
            className="presentationmoderator"
            id="moderator"
            style={{ right: "1rem" }}
          >
            <div className="chat-head">
              <h2>Attendee Questions</h2>
              {/* <img src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png" title="Expand Arrow" width="16" onClick={this.Togglechatbox} /> */}
            </div>
            <div className="chat-bodymes scrolltype" id="myScrollingChat">
              <div className="flexform  " id="messages"></div>
              <form
                id="message-form-1"
                onSubmit={this.sendMessageAdmin}
                className="chat-textmoderator"
              >
                <input
                  type="text"
                  className="message-editbox"
                  id="message-txt-1"
                  placeholder="Message..."
                  autoComplete="off"
                />
                <button type="submit" className="sendarrow">
                  <img className="send-arrow-img" src={SendImg} alt="Send" />
                </button>
              </form>
            </div>
          </div>
          <div
            className="presentationmoderator"
            id="moderator1"
            style={{ display: 'none' }}
          >
            <div className="chat-head">
              <h2>Speaker</h2>
            </div>
            <div
              className="chat-body scrolltype heigthchatbody"
              id="myScrollingChat2"
            >
              <div className="flexform " id="messages2"></div>
              <form
                id="message-form"
                onSubmit={this.sendMessage}
                className="chat-textmoderator"
              >
                <input
                  type="text"
                  className="message-editbox"
                  id="message-txt"
                  placeholder="Message..."
                  autoComplete="off"
                />
                <button type="submit" className="sendarrow">
                  <img className="send-arrow-img" src={SendImg} alt="Send" />
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="participantposition" style={{ display: "none" }}>
          <div
            className="presentationmoderator"
            id="partcipant"
            style={{ left: "1rem" }}
          >
            <div className="chat-headparticipants">
              <div>
                <h2>Participants</h2>
                <img src={closebtn} onClick={this.openparticipant} />
              </div>
              {/* <img src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png" title="Expand Arrow" width="16" onClick={this.Togglechatbox} /> */}
            </div>
            <div
              className="chat-bodymes scrolltype"
              id="myScrollingParticipantList"
              style={{ height: "245px" }}
            >
              <div className="flexform  " id="mainDiv">
                <div className="single-message flexparti">
                  <div className="name">Participant-1</div>
                  <img src={raisehandicon} className="raisehandwidth"></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer footerView="moderator"
                    liveButtonState={this.state.isModeratorLive}
                    speakerState={this.state.isSpeakerLive}
                    startCamera={this.startCamera}
                    speakerButton={this.speakerButtonClick}
                    moderatorStream={this.toggleModeratorLiveStream}
                /> */}
        <div
          className="participantsiconpositionmod"
          onClick={this.openSalesBooth}
        >
          <img
            src={salesRoom}
            alt="logout"
            className=" widthicon marginbottomimg"
            style={{ display: "flex" }}
            data-tooltip="tooltip"
            data-placement="right"
            title="Participants"
          />
          <div className="icontext textshadow">Sales Booth</div>
        </div>
        <div className="logouticonpositionmod" onClick={() => this.logOut()}>
          <img
            src={logout}
            alt="logout"
            className=" widthicon marginbottomimg"
            style={{ display: "flex" }}
            data-tooltip="tooltip"
            data-placement="right"
            title="Logout"
          />
          <div className="icontext textshadow">Logout</div>
        </div>

        {/* <div className="breakouticonposition1" onClick={() => this.goToBreakout1()}>
                    <img src={breakout} alt="nurses" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Breakout page 1" />
                    <div className="icontext textshadow">Breakout page 1</div>
                </div>
                <div className="breakouticonposition2" onClick={() => this.goToBreakout2()}>
                    <img src={breakout} alt="nurses" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Breakout page 2" />
                    <div className="icontext textshadow">Breakout page 2</div>
                </div>
                <div className="breakouticonposition3" onClick={() => this.goToBreakout3()}>
                    <img src={breakout} alt="nurses" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Breakout page 3" />
                    <div className="icontext textshadow">Breakout page 3</div>
                </div>
                <div className="breakouticonposition4" onClick={() => this.goToBreakout4()}>
                    <img src={breakout} alt="nurses" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Breakout page 4" />
                    <div className="icontext textshadow">Breakout page 4</div>
                </div>
                <div className="breakouticonposition5" onClick={() => this.goToBreakout5()}>
                    <img src={breakout} alt="nurses" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Breakout page 5" />
                    <div className="icontext textshadow">Breakout page 5</div>
                </div>
                <div className="breakouticonposition6" onClick={() => this.goToBreakout6()}>
                    <img src={breakout} alt="nurses" className="widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Go To Breakout page 6" />
                    <div className="icontext textshadow">Breakout page 6</div>
                </div> */}
      </div>
    );
  }
}
