import React, { Component } from "react";

import rotateMobile from "../images/rotate-mobile.png";
import Footer from "../footer/footer-page";
import Header from "../header/header";
import "./sales-page.css";
import { updateJwtAndTenant } from "./setup-jitsi";
import { updateLastSceneEndTime } from "../functions/helpers/analytics-function";

const salesBgImg =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/aakar-launch-sales-room-bg.png";
const roomName = "aakar-launch-sales";

export default class SalesPage extends Component {
  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "user" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");
    } else {
      window.location.href = "/";
    }
    updateLastSceneEndTime(roomName);
    document.getElementById("meet").style.display = "block";
    updateJwtAndTenant("melzoAakarLaunch");
  }
  getcoords = (event) => {
    var x = event.nativeEvent.offsetX;
    var y = event.nativeEvent.offsetY;
    console.log(x, y);
  };
  onhandleOpen=(e)=>{
    window.open(e)
  }
  render() {
    return (
      <div>
        <Header headerType="user" />
        <img
          src={salesBgImg}
          alt=""
          className="sales2dImg"
          usemap="#image-map"
          onClick={this.getcoords}
        />
        <map name="image-map">
          <area
            shape="rect"
            coords="46,326,160,469"
            style={{ cursor: "pointer" }}
            onClick={() => this.onhandleOpen("https://aakar.melzo.com")}
          />
          <area
            shape="rect"
            coords="1162,322,1276,468"
            style={{ cursor: "pointer" }}
            onClick={() => this.onhandleOpen("https://aakar.melzo.com")}
          />
        </map>
        <div className="overlaylandscape">
          <img src={rotateMobile} alt="" className="rotateimg" />
          <p className="rotatetext">
            Rotate your device to talk with our customer support executive
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}
