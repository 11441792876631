// import React, { Component } from 'react'
// import Footer from '../footer/footer-page'
// import Header from '../header/header';
// import './lobby-page.css'
// const lobbyUrl = 'https://angles-expo.firebaseapp.com/dekho/5fa8fec1e0fe1c921af6a5b4/0/novr/none';

// export default class LobbyPage extends Component {


//     componentDidMount() {


//         window.addEventListener("message", (e) => {
//             console.warn('called on lobby arrow', e.data);
//             if (e.data.isImageClicked) {
//                 console.warn('e.msl Is ::', e.data.mslKey)

//                 if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar') {
//                     window.location.href = '/webinar-hall'
//                 } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/breakout') {
//                     window.location.href = '/breakout-page'
//                 } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/entertainment') {
//                     window.location.href = '/break-time-zone'
//                 } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/display') {
//                     window.location.href = '/display-gallary'
//                 } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive') {
//                     window.location.href = '/archive-gallary'
//                 } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/chat') {
//                     window.location.href = '/lobby-page'
//                 }
//             }
//         }, false);

//     }

//     render() {
//         return (
//             <div>
//                 <Header />
//                  <iframe id="entranceBackground" className="iframe-position" title="VR background"
//                     allowvr="yes" scrolling="no" src={lobbyUrl}>
//                 </iframe>
//                 <Footer />
//             </div>
//         )
//     }
// }



import React, { Component } from 'react';
import firebaseOther from '../functions/features/firebase-app';
import loginFirebaseApp from '../functions/features/login-firebase-app';
// import { PAGETYPE } from '../../functions/helpers/constants';
import Footer from '../footer/footer-page'
import Header from '../header/header';
import './lobby-page.css'
import ARClickHereImg from '../images/AR CLICK HERE.png';
import backLabel from '../images/backlabel.png'
import selfielabel from '../images/selfie boothlabel.png'
import gotoarlabel from '../images/way to arlabel.png'
import receptionlabel from '../images/way to reception arealabel.png'
import waytoselfieboothlabel from '../images/way to selfie boothlabel.png'
import startCameraImg from '../images/medexIcon/camera.png'
import stopCameraImg from '../images/medexIcon/off-cam.png'
import CaptureImg from '../images/medexIcon/capture.png'
import arrow from '../images/button arrow-1.png'
import playImg from '../images/medexIcon/play-med.png'
import pauseImg from '../images/medexIcon/pause-video-med.png'
import whiteImg from '../images/white.png'
import frameImg from '../images/FRAME.png'
import '../functions/helpers/c-zoom-in-out'
import 'aframe';
// import 'aframe-extras';
import 'aframe-html-shader';
import dbFirebaseApp from '../functions/features/db-firebase-app';
// import { isMobileDevice } from '../functions/helpers/other-function';
// import LoadingPage from '../loading/loading-page';
const lobbyliteArModeSky = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/gulf-ar-model1.jpg"
const lobbyliteSelfieBoothSky = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/gulf-selfie-booth1.jpg"
const lobbyliteZoneAreaSky = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/gulf-zone-area1.jpg"
const lobbyliteEntranceSky = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/gulf-entrance1.jpg"
let canvasDrawRequest = null;
const sceneName = 'lobby'
var snapshots = [];
var slideIndex = 0;

let galleryImages = []
let capturedSelfieImage = ''
let k = 0;
let myCameraFov = false;
const promoVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Angel_20_11_2020_Low.mp4';
// import changePlayerImg from '../../images/change-player.png';

export default class LobbyLiteNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCameraStarted: false,
        }
        this.videoRef = React.createRef();
        this.inputStreamRef = React.createRef();

    }
    componentDidMount() {
        localStorage.setItem('UserName','Heaven Test')
        localStorage.setItem('ProfilePicUrl','https://firebasestorage.googleapis.com/v0/b/vr-expo.appspot.com/o/angels%2F530b57d0-c604-98a6-b432-387863af6c1e?alt=media&token=9ee59c1a-0bea-4d49-bf15-9e2a27f17317')
        // localStorage.setItem('uid',Math.random())
        myCameraFov = false;
        window.addEventListener("message", this.projectButtonClick, false);
        document.addEventListener('touchstart', this.onTouchStart);
        document.getElementById('modelVideo').setAttribute('src', promoVideo);

        if (localStorage.getItem('Location') !== 'lobby-lite') {
            this.GotoZoneArea();
        }
        else if (localStorage.getItem('selfieMode') === 'true') {
            this.GotoSelfieBooth();
        }
        else {
            this.GotoEntarance();
        }
        localStorage.setItem('Location', 'lobby-lite')
       
        // setTimeout(() => {

        //     let iframeEl = document.getElementById('lobbyLiteProject');
        //     if (iframeEl) {
        //         const userInfo = {
        //             name: localStorage.getItem('name'),
        //             mobileno: localStorage.getItem('mobile'),
        //             email: localStorage.getItem('email'),
        //             expo: 'angels-meta'
        //         }
        //         iframeEl.contentWindow.postMessage(userInfo, '*');
        //     }
        // }, 4000);

        let slideImagePromise = new Promise((resolve, reject) => {
            firebaseOther.firestore().collection('Medex').doc('SelfieZone').get()
                .then(doc => {
                    if (doc.exists)
                        galleryImages = doc.data().gulfGalleryImages
                    console.log('gallery images', galleryImages)
                    resolve(galleryImages);
                })
                .catch(function (error) {
                    // Handle Errors here.

                    reject(error)
                    // ...
                });

        });
        slideImagePromise.then(result => {
            console.warn('Successful', result)
            galleryImages = result
            this.showSlideImage();


        }).catch(error => {
            console.error(error)
            var errorMessage = error.message;
            // alert(errorMessage)
        })
       if(localStorage.getItem('docRef')) {
            console.warn('not need to entry')
        }else{
            this.addNewEntry();
        }
       
    }

    addNewEntry=()=>{
        const db = dbFirebaseApp.firestore();
        db.collection("AngelsKnowledgeVillage").add({
            name: localStorage.getItem('UserName'),
            profilePicUrl: localStorage.getItem('ProfilePicUrl'),
            sceneName: sceneName,
            uid: localStorage.getItem('uid')
        })
        .then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
            localStorage.setItem('docRef',docRef.id)
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
        document.removeEventListener('touchstart', this.onTouchStart);
    }

    onTouchStart = (event) => {
        // if (isMobileDevice() && myCameraFov === false) {
            if (document.querySelector('#camera-lobbylite')) {
                document.querySelector('#camera-lobbylite').setAttribute('fov', '79');
                setTimeout(() => {
                    document.querySelector('#camera-lobbylite').setAttribute('fov', '80');
                }, 100);
                myCameraFov = true;
            }
        // }
    }

    projectButtonClick = (e) => {
        console.warn('called on lobby arrow', e.data);
        if (e.data.isImageClicked) {
            console.warn('e.msl Is ::', e.data.mslKey)

            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/webinar') {
                window.location.href = '/webinar'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/breakout') {
                window.location.href = '/breakout-page'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/entertainment') {
                window.location.href = '/entertainment-zone'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/productlaunch') {
                window.location.href = '/product-launch'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive') {
                window.location.href = '/archive-gallary'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/chat') {
                window.location.href = '/lobby-page'
            }
        }
    }



    async startCamera() {
        try {
            const CAMERA_CONSTRAINTS = {
                // audio: true,
                video: true,
            };
            this.inputStreamRef.current = await navigator.mediaDevices.getUserMedia(
                CAMERA_CONSTRAINTS
            );

            this.videoRef.current.srcObject = this.inputStreamRef.current;

            await this.videoRef.current.play();

            this.setState({ isCameraStarted: true });
            document.getElementById('slide-show').setAttribute('visible', false)
            document.getElementById('selfieDisplay').setAttribute('visible', true)
            document.getElementById('captureBtn').setAttribute('visible', true)
            document.getElementById('captureBtn').setAttribute('position', '3.432 2.614 -5')
            document.getElementById('stopcameraBtn').setAttribute('visible', true)
            document.getElementById('stopcameraBtn').setAttribute('position', '1.432 2.414 -5')
            document.getElementById('startCameraBtn').setAttribute('visible', false)
            document.getElementById('startCameraBtn').setAttribute('position', '0 200 0')
            // this.startStreaming();
            // setTimeout(() => {
            this.drawCanvas();
            // }, 5000);
        } catch (e) {
            console.warn("Camera start error", e);
        }

    }


    drawCanvas = () => {
        var canvas = document.getElementById('selfieCanvas');
        var video = document.getElementById('videoselfie');
        if (canvas && video) {
            canvas.width = 1487;
            canvas.height = 872;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, 1487, 872);
            let myImage = new Image();
            myImage.src = frameImg
            // setTimeout(() => {
            ctx.drawImage(myImage, 0, 0, 1487, 872);
        }
        // }, 10);
        canvasDrawRequest = requestAnimationFrame(this.drawCanvas);
    }

    drawCanvasforSlideShow = (image) => {
        console.warn('image in function is ', image)
        var canvas = document.getElementById('slide-Canvas');
        // var video = document.getElementById('videoselfie');
        if (canvas) {
            canvas.width = 500;
            canvas.height = 300;
            var ctx = canvas.getContext('2d');

            let myImage = new Image();
            myImage.src = image.src
            myImage.crossOrigin = "";
            // setTimeout(() => {
            ctx.drawImage(myImage, 0, 0, 500, 300);
        }
        // }, 10);
    }


    stopCamera() {
        console.warn('Stop Camera');
        if (this.inputStreamRef.current !== null) {
            this.inputStreamRef.current.getTracks().forEach(track => {
                track.stop();
            });
        };
        // this.videoRef.current.srcObject = '';
        this.setState({ isCameraStarted: false });
        document.getElementById('selfieDisplay').setAttribute('visible', false)
        document.getElementById('captureBtn').setAttribute('visible', false)
        document.getElementById('captureBtn').setAttribute('position', '0 200 0')
        document.getElementById('stopcameraBtn').setAttribute('visible', false)
        document.getElementById('stopcameraBtn').setAttribute('position', '0 200 0')
        document.getElementById('startCameraBtn').setAttribute('visible', true)
        document.getElementById('startCameraBtn').setAttribute('position', '2.432 2.614 -5')
        document.getElementById('slide-show').setAttribute('visible', true)
    }




    captureCamera() {
        console.warn('capture camera')
        var video = document.getElementById('videoselfie');
        var output = document.getElementById('output');
        var canvas = document.createElement('canvas');
        canvas.setAttribute('id', 'shootCanvas')
        canvas.width = 1487;
        canvas.height = 872;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, 1487, 872);
        let myImage = new Image();
        myImage.src = frameImg


        const guid = () => {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return String(s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4());
        }
        setTimeout(() => {

            ctx.drawImage(myImage, 0, 0, 1487, 872)
            snapshots.unshift(canvas);
            output.innerHTML = '';
            for (var i = 0; i < 4; i++) {
                output.appendChild(snapshots[0]);
            }
        }, 10);


        setTimeout(() => {

            canvas.toBlob(function (blob) {

                console.log(blob);

                let myPromise = new Promise((resolve, reject) => {


                    const myGuid = guid();
                    const storageUrl = loginFirebaseApp.storage('gs://vr-expo.appspot.com/')
                    const storageRef = storageUrl.ref();

                    const uploadTask = storageRef.child('angels').child(myGuid).put(blob)
                    uploadTask.on('state_changed',
                        (snapShot) => {

                        }, (err) => {
                            //catches the errors
                            console.log(err)
                            reject(err)
                        }, () => {

                            loginFirebaseApp
                                .storage('gs://vr-expo.appspot.com/')
                                .ref()
                                .child('angels')
                                .child(myGuid)
                                .getDownloadURL()
                                .then(fireBaseUrl => {
                                    resolve(fireBaseUrl)
                                })
                        })
                })
                myPromise.then(url => {
                    console.log(url)

                    capturedSelfieImage = url
                    // this.saveSelefieData()
                    galleryImages = [...galleryImages, capturedSelfieImage]

                    console.log('gallary images', galleryImages)
                    console.log('captured selfie images', capturedSelfieImage)
                    firebaseOther.firestore().collection('Medex').doc('SelfieZone').update({
                        gulfGalleryImages: galleryImages
                    })
                })


                // this line should be here
            }, 'image/png');


        }, 1000)


        setTimeout(() => {
            var downloadLink = document.getElementById('downloader')
            downloadLink.click();
        }, 10);

        // this.downloadImage();

    }

    downloadImage = () => {
        document.getElementById("downloader").download = "image.png";
        document.getElementById("downloader").href = document.getElementById("shootCanvas").toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');

    }



    GotoArMode = () => {
        console.warn('ar mode')
        document.getElementById('gotoar').setAttribute('visible', false)
        document.getElementById('gotoar').setAttribute('position', '0 200 0')
        document.getElementById('arlabel1').setAttribute('visible', false)
        document.getElementById('backtoentrance').setAttribute('visible', true)
        document.getElementById('backlabel1').setAttribute('visible', true)
        document.getElementById('backtoentrance').setAttribute('position', '2.8550252940207317 -6.005471017385487 17.982349314231634')
        document.getElementById('gotoselfie').setAttribute('visible', true)
        document.getElementById('gotoselfie').setAttribute('position', '8.366 -1.76 -2.317')
        document.getElementById('waytoselfielabel1').setAttribute('visible', true)
        document.getElementById('askylobbylite').setAttribute('src', '#skyLobbyLiteArmode')
        document.getElementById('backtoarmode').setAttribute('visible', false)
        document.getElementById('backtoarmode').setAttribute('position', '0 200 0')
        document.getElementById('backtoarlabel').setAttribute('visible', false)
        document.getElementById('gotozone').setAttribute('visible', false)
        document.getElementById('modelvideo2').setAttribute('visible', false)
        this.pauseVideo2();
        document.getElementById('PlayBtn2').setAttribute('visible', false)
        document.getElementById('PlayBtn2').setAttribute('position', '0 200 0')
        document.getElementById('gotozone').setAttribute('position', '0 200 0')
        document.getElementById('receptionlabel1').setAttribute('visible', false)
        document.getElementById('startCameraBtn').setAttribute('visible', false)
        document.getElementById('startCameraBtn').setAttribute('position', '0 200 0')
        document.getElementById('PlayBtn').setAttribute('visible', true)
        document.getElementById('PlayBtn').setAttribute('position', "6.844685774433675 3.19587697358012 1.3545483974754")
        document.getElementById('modelvideo1').setAttribute('visible', true)
        document.getElementById('modelvideo1').setAttribute('position', '9.59845715633523 1.854956714835778 1.7699637329321083')
        document.getElementById('slide-show').setAttribute('visible', false)
        document.getElementById('slide-show2').setAttribute('visible', true)
        document.getElementById('gotoarchive').setAttribute('visible', false)
        document.getElementById('gotoarchive').setAttribute('position', '0 200 0')
        document.getElementById('gotonurses').setAttribute('visible', false)
        document.getElementById('gotonurses').setAttribute('position', '0 200 0')

        document.getElementById('gotowebinar').setAttribute('visible', false)
        document.getElementById('gotowebinar').setAttribute('position', '0 200 0')

        document.getElementById('gotodisplay').setAttribute('visible', false)
        document.getElementById('gotodisplay').setAttribute('position', '0 200 0')

        document.getElementById('gotobreaktime').setAttribute('visible', false)
        document.getElementById('gotobreaktime').setAttribute('position', '0 200 0')

        document.getElementById('boxar').setAttribute('position', '-1.131276216152886 0.605485578261407 -5.604535034666')
        document.getElementById('boximage').setAttribute('position', '-1.131276216152886 3.05485578261407 -5.604535034666')
        document.getElementById('boximage').setAttribute('visible', true)
    }

    GotoSelfieBooth = () => {
        console.warn('selfie booth')
        localStorage.removeItem('selfieMode');
        document.getElementById('gotoar').setAttribute('visible', false)
        document.getElementById('gotoar').setAttribute('position', '0 200 0')
        document.getElementById('arlabel1').setAttribute('visible', false)
        document.getElementById('backtoentrance').setAttribute('visible', false)
        document.getElementById('backlabel1').setAttribute('visible', false)
        document.getElementById('backtoentrance').setAttribute('position', '0 200 0')
        document.getElementById('boxar').setAttribute('position', '0.62626186353392945 0.646173354956104 6.930')
        document.getElementById('boximage').setAttribute('position', '0.62626186353392945 3.046173354956104 6.930')
        document.getElementById('boximage').setAttribute('visible', true)
        document.getElementById('startCameraBtn').setAttribute('visible', true)
        document.getElementById('startCameraBtn').setAttribute('position', '2.432 2.614 -5')
        document.getElementById('slide-show').setAttribute('visible', true)
        document.getElementById('slide-show2').setAttribute('visible', false)
        document.getElementById('gotoselfie').setAttribute('visible', false)
        document.getElementById('gotoselfie').setAttribute('position', '0 200 0')
        document.getElementById('waytoselfielabel1').setAttribute('visible', false)
        document.getElementById('gotozone').setAttribute('visible', true)
        document.getElementById('gotozone').setAttribute('position', '-3.986276135582242 -0.575103024849713 5.37354453196513')
        document.getElementById('receptionlabel1').setAttribute('visible', true)
        document.getElementById('backtoselfie').setAttribute('visible', false)
        document.getElementById('backtoselfielabel').setAttribute('visible', false)
        document.getElementById('backtoselfie').setAttribute('position', '0 200 0')
        document.getElementById('backtoarmode').setAttribute('visible', true)
        document.getElementById('backtoarmode').setAttribute('position', '4.605801771622758 -0.8672057816984 4.085648265608867')
        document.getElementById('backtoarlabel').setAttribute('visible', true)
        this.pauseVideo();
        document.getElementById('PlayBtn').setAttribute('visible', false)
        document.getElementById('PlayBtn').setAttribute('position', "0 200 0")
        document.getElementById('modelvideo1').setAttribute('visible', false)
        document.getElementById('modelvideo2').setAttribute('visible', true)
        document.getElementById('PlayBtn2').setAttribute('visible', true)
        document.getElementById('PlayBtn2').setAttribute('position', '15.637 5 -4.427')
        document.getElementById('modelvideo1').setAttribute('position', "0 200 0")
        document.getElementById('gotoarchive').setAttribute('visible', false)
        document.getElementById('gotoarchive').setAttribute('position', '0 200 0')
        document.getElementById('gotonurses').setAttribute('visible', false)
        document.getElementById('gotonurses').setAttribute('position', '0 200 0')

        document.getElementById('gotowebinar').setAttribute('visible', false)
        document.getElementById('gotowebinar').setAttribute('position', '0 200 0')

        document.getElementById('gotodisplay').setAttribute('visible', false)
        document.getElementById('gotodisplay').setAttribute('position', '0 200 0')

        document.getElementById('gotobreaktime').setAttribute('visible', false)
        document.getElementById('gotobreaktime').setAttribute('position', '0 200 0')
        document.getElementById('askylobbylite').setAttribute('src', '#skyLobbyLiteSelfieBooth')
    }

    GotoZoneArea = () => {
        console.warn('zone area')

        document.getElementById('gotoar').setAttribute('visible', false)
        document.getElementById('gotoar').setAttribute('position', '0 200 0')
        document.getElementById('arlabel1').setAttribute('visible', false)

        document.getElementById('modelvideo2').setAttribute('visible', false)
        this.pauseVideo2();
        document.getElementById('PlayBtn2').setAttribute('visible', false)
        document.getElementById('PlayBtn2').setAttribute('position', '0 200 0')
        document.getElementById('boxar').setAttribute('position', '6.7 0.3 -5.040')
        document.getElementById('boximage').setAttribute('position', '6.7 3 -5.040')
        document.getElementById('boximage').setAttribute('visible', true)
        document.getElementById('gotozone').setAttribute('visible', false)
        document.getElementById('gotozone').setAttribute('position', '0 200 0')
        document.getElementById('receptionlabel1').setAttribute('visible', false)
        document.getElementById('startCameraBtn').setAttribute('visible', false)
        document.getElementById('startCameraBtn').setAttribute('position', '0 200 0')
        document.getElementById('slide-show').setAttribute('visible', false)
        document.getElementById('slide-show2').setAttribute('visible', false)
        document.getElementById('backtoselfie').setAttribute('visible', true)
        document.getElementById('backtoselfie').setAttribute('position', '-0.930321205937364 -7.907217282762484 -19.02523549553357')
        document.getElementById('backtoselfielabel').setAttribute('visible', true)
        document.getElementById('backtoarmode').setAttribute('visible', false)
        document.getElementById('backtoarmode').setAttribute('position', '0 200 0')
        document.getElementById('backtoarlabel').setAttribute('visible', false)
        this.pauseVideo();
        document.getElementById('PlayBtn').setAttribute('visible', false)
        document.getElementById('modelvideo1').setAttribute('visible', false)
        document.getElementById('PlayBtn').setAttribute('position', "0 200 0")
        document.getElementById('gotoarchive').setAttribute('visible', true)
        document.getElementById('gotoarchive').setAttribute('position', '-14.181832923001851 2.5166209348080812 -14.496044459518355')
        document.getElementById('gotonurses').setAttribute('visible', true)
        document.getElementById('gotonurses').setAttribute('position', '-19.454212983126652 2.2929249922869483 -6.674746440515585')

        document.getElementById('gotowebinar').setAttribute('visible', true)
        document.getElementById('gotowebinar').setAttribute('position', '-16.28679175611858 2.827588263613977 12.342381387813724')

        document.getElementById('gotodisplay').setAttribute('visible', true)
        document.getElementById('gotodisplay').setAttribute('position', '2.0817102539023815 2.0943412325687327 20.129491352670936')

        document.getElementById('gotobreaktime').setAttribute('visible', true)
        document.getElementById('gotobreaktime').setAttribute('position', '11.565749361209553 2.2818774286003727 17.714910753327178')

        document.getElementById('askylobbylite').setAttribute('src', '#skyLobbyLiteZoneArea')
    }

    GotoEntarance = () => {
        document.getElementById('gotoselfie').setAttribute('visible', false)
        document.getElementById('gotoselfie').setAttribute('position', '0 200 0')
        document.getElementById('waytoselfielabel1').setAttribute('visible', false)
        document.getElementById('backtoarmode').setAttribute('visible', false)
        document.getElementById('backtoarmode').setAttribute('position', '0 200 0')
        document.getElementById('backtoarlabel').setAttribute('visible', false)
        document.getElementById('backtoentrance').setAttribute('visible', false)
        document.getElementById('backlabel1').setAttribute('visible', false)
        document.getElementById('backtoentrance').setAttribute('position', '0 200 0')
        document.getElementById('gotozone').setAttribute('visible', false)
        document.getElementById('slide-show2').setAttribute('visible', false);
        document.getElementById('gotozone').setAttribute('position', '0 200 0')
        document.getElementById('receptionlabel1').setAttribute('visible', false)
        document.getElementById('gotoar').setAttribute('visible', true)
        document.getElementById('gotoar').setAttribute('position', '-0.8 -0.6 -7.328')
        document.getElementById('arlabel1').setAttribute('visible', true)
        document.getElementById('startCameraBtn').setAttribute('visible', false)
        document.getElementById('startCameraBtn').setAttribute('position', '0 200 0')
        document.getElementById('slide-show').setAttribute('visible', false)
        document.getElementById('boxar').setAttribute('position', '4.3 0 -16.52')
        document.getElementById('boximage').setAttribute('position', '4.3 3 -16.52')
        document.getElementById('boximage').setAttribute('visible', true)
        this.pauseVideo();
        document.getElementById('PlayBtn').setAttribute('visible', false)
        document.getElementById('modelvideo1').setAttribute('visible', false)
        document.getElementById('PlayBtn').setAttribute('position', "9.837 20 -4.427")
        document.getElementById('askylobbylite').setAttribute('src', '#skyLobbyLiteEntrance')
    }

    updateSrcAimage = () => {
        // for (let i = 0; i < galleryImages.length; i++) {
        // let i=0
        let slideShowElement = document.getElementById('slide-show')
        if (slideShowElement) {
            slideShowElement.setAttribute('src', '#img' + k)
            k++
            if (k === galleryImages.length) {
                k = 0
            }
            // }
            setTimeout(() => {
                this.updateSrcAimage();
            }, 2000);
        }
        let slideShowElement2 = document.getElementById('slide-show2')
        if (slideShowElement2) {
            slideShowElement2.setAttribute('src', '#img' + k)

            // }
        }

    }


    showSlideImage = () => {

        console.warn('images is :: ', galleryImages)
        for (let i = 0; i < galleryImages.length; i++) {
            let imageElement = document.createElement('img')
            imageElement.setAttribute("crossOrigin", "anonymous");
            imageElement.setAttribute('id', 'img' + i)
            imageElement.setAttribute('src', galleryImages[i])
            document.getElementById('aAsset').appendChild(imageElement)
            // let divElement = document.createElement('div')
            // let imageElement = document.createElement('img')
            // divElement.setAttribute('class', 'mySlides')
            // divElement.setAttribute('id','div'+i)

            // imageElement.setAttribute('src', galleryImages[i])
            // imageElement.setAttribute('id','image'+i)
            // // imageElement.setAttribute('class','myImage')
            // divElement.appendChild(imageElement)
            // document.getElementById('slideshow-container').appendChild(divElement)

        }
        this.updateSrcAimage();
        // setTimeout(() => {
        //     this.showSlides();
        // }, 1);
    }

    showSlides = () => {

        var slides = document.getElementsByClassName("mySlides");
        for (let i = 0; i < slides.length; i++) {
            var images = document.getElementById('image' + i)
        }
        console.warn('image src is ::', images.src)
        if (slides) {
            for (let i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            slideIndex++;
            if (slideIndex > slides.length) { slideIndex = 1 }
            for (let i = 0; i < slides.length; i++) {
                slides[i].className = slides[i].className.replace(" active", "");
            }
            if (slides[slideIndex - 1]) {
                slides[slideIndex - 1].style.display = "block";
                slides[slideIndex - 1].className += " active";
                this.drawCanvasforSlideShow(document.getElementById('image' + (slideIndex - 1)))
            }

            setTimeout(this.showSlides, 2000); // Change image every 2 seconds

        }

    }

    GotoArchiveGallary = () => {
        window.location.href = '/archive-gallary'
    }

    GotoNursesRoom = () => {
        window.location.href = '/breakout-page'
    }

    GotoWebinarHall = () => {
        window.location.href = '/webinar'
    }
    GotoDisplayGallary = () => {
        window.location.href = '/display-gallary'
    }
    GotoBreakTimeZone = () => {
        window.location.href = '/entertainment-zone'
    }

    gotoArPage = () => {
        window.open('ar-model.html', '_blank')
    }

    playVideo() {
        // console.error('playvideo')
        document.getElementById('pauseBtn').setAttribute('visible', true)
        document.getElementById('pauseBtn').setAttribute('position', "6.844685774433675 3.19587697358012 1.3545483974754")
        document.getElementById('PlayBtn').setAttribute('visible', false)
        document.getElementById('PlayBtn').setAttribute('position', "9.837 20 -4.427")
        document.getElementById('modelVideo').setAttribute('src', promoVideo);
        document.getElementById('modelVideo').play();
    }

    pauseVideo() {
        // console.error('pauseVideo')
        document.getElementById('PlayBtn').setAttribute('visible', true)
        document.getElementById('PlayBtn').setAttribute('position', "6.844685774433675 3.19587697358012 1.3545483974754")
        document.getElementById('pauseBtn').setAttribute('visible', false)
        document.getElementById('pauseBtn').setAttribute('position', "9.837 20 -4.427")
        document.getElementById('modelVideo').pause();
        document.getElementById('modelVideo').setAttribute('src', '');
    }
    playVideo2 = () => {
        // console.error('playvideo')
        document.getElementById('pauseBtn2').setAttribute('visible', true)
        document.getElementById('pauseBtn2').setAttribute('position', "15.637 5 -4.427")
        document.getElementById('PlayBtn2').setAttribute('visible', false)
        document.getElementById('PlayBtn2').setAttribute('position', "0 200 0")
        document.getElementById('modelVideo').setAttribute('src', promoVideo);
        document.getElementById('modelVideo').play();
    }

    pauseVideo2 = () => {
        // console.error('pauseVideo')
        document.getElementById('PlayBtn2').setAttribute('visible', true)
        document.getElementById('PlayBtn2').setAttribute('position', "15.637 5 -4.427")
        document.getElementById('pauseBtn2').setAttribute('visible', false)
        document.getElementById('pauseBtn2').setAttribute('position', "0 200 0")
        document.getElementById('modelVideo').pause();
        document.getElementById('modelVideo').setAttribute('src', '');
    }

    render() {
        return (
            <div>
                {/* {(this.props.isLobbySkyLoading) ?

                    <LoadingPage goToPage={this.props.goToPage} />
                    :
                    ''
                } */}
                <React.Fragment>
                    <Header />
                    <Footer />
                    {/* <Footer goToPage={this.props.goToPage} footerView="user" /> */}
                    {/* <img src={changePlayerImg} alt="change player" className="change-player-btn" onClick={()=>this.changePlayerBtn()} /> */}

                    {/* <div className="lobby-scene-btn" onClick={()=>this.props.goToPage(PAGETYPE.LOBBY)}>

                </div> */}


                    <video id="videoselfie" ref={this.videoRef} autoPlay playsInline muted style={{ position: 'fixed', top: '0px', left: '0px' }}
                    ></video>
                    {/* <video id="modelVideo" autoPlay playsInline muted crossOrigin="anonymous" src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Angels-Promo-Video.mp4"></video> */}
                    <div id="selfieImage" style={{ width: "100%", height: "100%", position: "fixed", left: "0", top: "0", zIndex: "-1", overflow: "hidden" }}>
                        <canvas id="selfieCanvas" style={{ width: '100%', height: '100%' }}  ></canvas>
                    </div>
                    {/* <div id="slideshowImage" style={{ width: "100%", height: "100%", position: "fixed", left: "0", top: "0", zIndex: "-1", overflow: "hidden" }}>
                        <canvas crossOrigin="anonymous" id="slide-Canvas" style={{ width: '100%' }} ></canvas>
                    </div> */}
                    <a href="#" id="downloader" onClick={this.downloadImage} download="image.png"></a>
                    <br />
                    <div id="output"></div>
                    <a-scene loading-screen="enabled: false" vr-mode-ui="enabled: false" style={{ zIndex: 2 }}>
                        <a-assets id="aAsset">
                            <img id="skyLobbyLiteEntrance" crossOrigin="anonymous" src={lobbyliteEntranceSky} alt="background" />
                            <img id="skyLobbyLiteArmode" crossOrigin="anonymous" src={lobbyliteArModeSky} alt="background" />
                            <img id="skyLobbyLiteSelfieBooth" crossOrigin="anonymous" src={lobbyliteSelfieBoothSky} alt="background" />
                            <img id="skyLobbyLiteZoneArea" crossOrigin="anonymous" src={lobbyliteZoneAreaSky} alt="background" />
                            <img id="backArrowentertainment" src={arrow} alt='back' />
                            <img id="startCamera" src={startCameraImg} alt="upload Image" />
                            <img id="stopCamera" src={stopCameraImg} alt="stop camera" />
                            <img id="captureImage" src={CaptureImg} alt="capture Image" />
                            <img id="whiteImageSelfie" src={whiteImg} alt='white' />
                            <img id="playvideo" src={playImg} alt="play Image" />
                            <img id="pauseVideo" src={pauseImg} alt="pause Image" />
                            <img id="backlabel" src={backLabel} alt='labelback' />
                            <img id="selfieboothlabel" src={selfielabel} alt="labelselfie" />
                            <img id="arlabel" src={gotoarlabel} alt="labelar" />
                            <img id="receptionlabel" src={receptionlabel} alt="labelreception" />
                            <img id="waytoselfielabel" src={waytoselfieboothlabel} alt="labelwaytoselfie" />
                            <video id="modelVideo" loop={true} playsInline crossOrigin="anonymous" src=''></video>
                        </a-assets>
                        <a-camera look-controls="gyroModeEnabled: false; magicWindowTrackingEnabled: false; reverseTouchDrag: true" c-zoom-in-out id="camera-lobbylite" wasd-controls="enabled:false" position="0 1.6 0" fov="80">
                        </a-camera>
                        <a-entity
                            cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
                            raycaster="objects: .raycaster-solid; recursive: false;"
                        />

                        <a-sky src="#skyLobbyLiteEntrance" id="askylobbylite" rotation="0 0 0" sky-lobby-component=""></a-sky>
                        <a-image src="#backArrowentertainment" id="gotoar" position="-0.8 -0.6 -7.328" rotation="30 0 0" scale="0.8 0.8 1" class="raycaster-solid clickable" onClick={this.GotoArMode}></a-image>
                        <a-image src="#backArrowentertainment" id="gotozone" position="-11.986276135582242 -7.175103024849713 16.37354453196513" rotation="70 -45 0" scale="1 1 1" visible='false' class="raycaster-solid clickable" onClick={this.GotoZoneArea}></a-image>
                        <a-image src="#backArrowentertainment" id="gotoselfie" position="16.596778907810656 -5.845421673985051 -9.940040202669174" rotation="75 90 -15" scale="1 1 1" visible='false' class="raycaster-solid clickable" onClick={this.GotoSelfieBooth}></a-image>
                        <a-image src="#backArrowentertainment" id="backtoselfie" position="0.011230321205937364 -7.907217282762484 -19.02523549553357" height="2.5" width="2.5" rotation="30 0 0" scale="1 1 1" visible='false' class="raycaster-solid clickable" onClick={this.GotoSelfieBooth}></a-image>
                        {/* <a-image src="#backArrowentertainment" id="backtoar" position="0 0 0" rotation="0 90 0" scale="1 1 1" visible='false' class="raycaster-solid clickable" onClick={this.GotoArMode}></a-image> */}
                        <a-image src="#backArrowentertainment" id="backtoarmode" position="14.605801771622758 -7.507672057816984 12.085648265608867" rotation="75 65 20" scale="0.8 0.8 1" visible='false' class="raycaster-solid clickable" onClick={this.GotoArMode}></a-image>
                        <a-image src="#backArrowentertainment" id="backtoentrance" height="2.5" width="2.5" position="-3.6731276216152886 6.605485578261407 -19.821604535034666" rotation="65 0 0" scale="1 1 1" visible='false' class="raycaster-solid clickable" onClick={this.GotoEntarance}></a-image>
                        <a-image src="#backArrowentertainment" look-at="#camera-lobbylite" id="gotoarchive" position="0 200 0" rotation="0 80 0" scale="1.8 1.8 1.8" visible='false' class="raycaster-solid clickable" onClick={this.GotoArchiveGallary}></a-image>
                        <a-image src="#backArrowentertainment" look-at="#camera-lobbylite" id="gotonurses" position="0 200 0" rotation="0 100 0" scale="1.8 1.8 1.8" visible='false' class="raycaster-solid clickable" onClick={this.GotoNursesRoom}></a-image>
                        <a-image src="#backArrowentertainment" look-at="#camera-lobbylite" id="gotowebinar" position="0 200 0" rotation="0 0 0" scale="1.8 1.8 1.8" visible='false' class="raycaster-solid clickable" onClick={this.GotoWebinarHall}></a-image>
                        <a-image src="#backArrowentertainment" look-at="#camera-lobbylite" id="gotodisplay" position="0 200 0" rotation="0 45 0" scale="1.8 1.8 1.8" visible='false' class="raycaster-solid clickable" onClick={this.GotoDisplayGallary}></a-image>
                        <a-image src="#backArrowentertainment" look-at="#camera-lobbylite" id="gotobreaktime" position="0 200 0" rotation="0 45 0" scale="1.8 1.8 1.8" visible='false' class="raycaster-solid clickable" onClick={this.GotoBreakTimeZone}></a-image>
                        <a-image src="#whiteImageSelfie" id="selfieDisplay" position="2.842008245498107 0.203517826649745 -5.6283259648335" rotation="0 -25 0" scale="4.7 3.3 3"
                            material="shader: html; target: #selfieImage; fps: 10"
                        ></a-image>
                        <a-image id="slide-show" visible='false' position="2.842008245498107 0.203517826649745 -5.6283259648335" rotation="0 -25 0" scale="4.7 3.3 3"
                        // material="shader: html; target: #slideshowImage; fps: 10"
                        ></a-image>
                        <a-image id="slide-show2" visible='false' position="10.842008245498107 1.1517826649745 -3.7283259648335" rotation="0 75 0" scale="3.1 2.2 3"
                        // material="shader: html; target: #slideshowImage; fps: 10"
                        ></a-image>
                        <a-image src="#startCamera" look-at="#camera-lobbylite" id="startCameraBtn" position="0 200 0" visible="false" scale="0.8 1 0.8" class="raycaster-solid clickable" onClick={() => this.startCamera()}></a-image>
                        <a-image src="#stopCamera" look-at="#camera-lobbylite" id="stopcameraBtn" position="0 200 0" visible="false" scale="0.8 1 0.8" class="raycaster-solid clickable" onClick={() => this.stopCamera()}></a-image>
                        <a-image src="#captureImage" look-at="#camera-lobbylite" id="captureBtn" position="0 200 0" visible="false" scale="0.8 1 0.8" class="raycaster-solid clickable" onClick={() => this.captureCamera()}></a-image>
                        <a-video src="#modelVideo" look-at="#camera-lobbylite" id="modelvideo1" look-at="#camera-lobbylite" visible="false" position="9.837 2.245 -4.427" rotation="0 242 0" scale="4.3 2.5 3" ></a-video>
                        <a-video src="#modelVideo" id="modelvideo2" visible="false" position="15.637 1.0 -4.427" rotation="0 -25 0" scale="8 5.3 2.5" ></a-video>
                        <a-image src="#playvideo" id="PlayBtn2" visible='false' position="0 200 0" rotation="0 -25 0" scale="2 2 2" class="raycaster-solid clickable" onClick={() => this.playVideo2()}></a-image>
                        <a-image src="#pauseVideo" id="pauseBtn2" visible='false' position="0 200 0" rotation="0 -25 0" scale="2 2 2" class="raycaster-solid clickable" onClick={() => this.pauseVideo2()}></a-image>

                        <a-image src="#playvideo" look-at="#camera-lobbylite" id="PlayBtn" look-at="#camera-lobbylite" visible='false' position="0 200 0" rotation="0 242 0" scale="0.8 0.8 0.8" class="raycaster-solid clickable" onClick={() => this.playVideo()}></a-image>
                        <a-image src="#pauseVideo" look-at="#camera-lobbylite" id="pauseBtn" position="0 200 0" visible="false" rotation="0 242 0" scale="0.8 0.8 0.8" class="raycaster-solid clickable" onClick={() => this.pauseVideo()}></a-image>
                        <a-box id='boxar' look-at="#camera-lobbylite" color="tomato" visible="false" position="4.3 0 -16.52" class="raycaster-solid clickable" onClick={() => this.gotoArPage()} scale="1.5 1.2 1" depth="1" width='12' height='1.4' width="0.5"></a-box>
                        <a-box id='boximage' look-at="#camera-lobbylite" src={ARClickHereImg} position="4.3 3 -16.52" class="raycaster-solid clickable" onClick={() => this.gotoArPage()} scale="1 1 1" depth="1.5" width='1.5' height='0.75'></a-box>
                        <a-image visible="false" src="#backlabel" scale="5 1.8 1" rotation="0 180 0" position="2.8550252940207317 -3.8471017385487 17.982349314231634" id="backlabel1"></a-image>
                        <a-image src="#arlabel" position="-1 -0.1 -8.328" scale='2.7 0.7 1' id="arlabel1"></a-image>
                        <a-image visible="false" src="#waytoselfielabel" position="8.366 -0.9 -2.317" scale="4 1 1" rotation="0 -70 0" id="waytoselfielabel1"></a-image>
                        <a-image visible="false" src="#receptionlabel" scale="3.2 0.7 1" rotation="0 150 0" position="-3.986276135582242 0.175103024849713 5.37354453196513" id="receptionlabel1"></a-image>
                        <a-image visible="false" src="#selfieboothlabel" scale="6.5 1.8 1" rotation="0 0 0" position="-1.30321205937364 -6.417282762484 -20.02523549553357" id="backtoselfielabel"></a-image>
                        <a-image visible="false" src="#arlabel" scale="2.8 0.7 1" rotation="0 210 0" position="4.605801771622758 -0.17672057816984 4.085648265608867" id="backtoarlabel"></a-image>
                    </a-scene>

                    {/* <iframe id="lobbyLiteProject" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src="https://angles-expo.firebaseapp.com/dekho/5fc0e2b921401f0018902c55/0/novr/none">
                </iframe> */}
                </React.Fragment>
            </div>
        )
    }


}
